import ReactGA from 'react-ga'
import Product from '../../api/models/Product'
import Cart from '../../api/models/Cart'
import cloneDeep from 'lodash/clone'

import {
  SET_ID,
  ADD,
  PRODUCT_QUANTITY_CHANGED,
  CLEAR_PRODUCT,
  REMOVE,
  CLEAR_CART,
  UPDATE_CHOICES,
  UPDATE_COMMENTS
} from '../../actions/cart'

import {
  SET_PRODUCT_SHIPPING_ENABLED
} from '../../actions/activeStore'

const initialState = new Cart()

type CartActions =
  SET_ID |
  ADD |
  PRODUCT_QUANTITY_CHANGED |
  CLEAR_PRODUCT |
  REMOVE |
  CLEAR_CART |
  SET_PRODUCT_SHIPPING_ENABLED |
  UPDATE_CHOICES |
  UPDATE_COMMENTS


export default (state = initialState, action: CartActions) => {
  switch (action.type) {
    case 'SET_ID': {
      const cart = new Cart({
        shortId: action.shortId,
        storeName: action.storeName
      })
      return cart
    }
    case 'ADD': {
      const itemIndex = action.product._id ? itemIndexInCart(action.product._id) : -1
      const cart = cloneDeep(state)
      if (itemIndex !== -1) {
        const product = { ...state.products[itemIndex] }
        product.count++
        if (action.itemNumber !== undefined) {
          product.chosen_sub_options.push([...product.chosen_sub_options[action.itemNumber]])
        } else {
          product.chosen_sub_options.push([])
        }
        cart.products = [
          ...state.products.slice(0, itemIndex),
          new Product(product),
          ...state.products.slice(itemIndex + 1)
        ]
      } else {
        const product = cloneDeep(action.product)
        product.count = 1
        product.chosen_sub_options = [[]]
        cart.products = [...state.products, product]
      }

      ReactGA.event({
        category: 'cart',
        action: 'add',
        label: `${action.product.name}`
      })

      return new Cart(cart)
    }

    case 'PRODUCT_QUANTITY_CHANGED': {
      const cart = { ...state }
      const newCart = cloneDeep(cart)
      const itemIndex = itemIndexInCart(action.productId)
      if (itemIndex !== -1) {
        const product = { ...state.products[itemIndex] }
        product.count = action.newStock
        newCart.products = [
          ...state.products.slice(0, itemIndex),
          new Product(product),
          ...state.products.slice(itemIndex + 1)
        ]
      }
      return new Cart(newCart)
    }

    case 'CLEAR_PRODUCT': {
      const cart = cloneDeep(state)
      const itemIndex = itemIndexInCart(action.productId)
      const products = [
        ...state.products.slice(0, itemIndex),
        ...state.products.slice(itemIndex + 1)
      ]

      cart.products = products
      return new Cart(cart)
    }

    case 'REMOVE': {
      // decreasing product count or removing it if is last
      const cart = cloneDeep(state)
      if (action.product._id) {
        const itemIndex = itemIndexInCart(action.product._id)
        if (itemIndex !== -1 && state.products[itemIndex].count >= 2) {
          const product = { ...state.products[itemIndex] }
          product.count--
          if (action.itemNumber !== undefined) {
            product.chosen_sub_options.splice(action.itemNumber, 1)
          } else {
            product.chosen_sub_options.pop()
          }
          cart.products = [
            ...state.products.slice(0, itemIndex),
            new Product(product),
            ...state.products.slice(itemIndex + 1)
          ]
        } else if (itemIndex !== -1 && state.products[itemIndex].count === 1) {
          cart.products = [
            ...state.products.slice(0, itemIndex),
            ...state.products.slice(itemIndex + 1)
          ]
        }
      }

      ReactGA.event({
        category: 'cart',
        action: 'remove',
        label: `${action.product.name}`
      })

      return new Cart(cart)
    }

    case 'SET_PRODUCT_SHIPPING_ENABLED': {
      const clonedCart = cloneDeep(state)
      clonedCart.changeProductShippingEnabled(action.productId, action.status)
      return new Cart(clonedCart)
    }

    case 'CLEAR_CART': {
      const clonedCart = cloneDeep(state)
      clonedCart.products = []
      return new Cart(clonedCart)
    }

    case 'UPDATE_CHOICES': {
      const cart = { ...state }
      const newCart = cloneDeep(cart)
      const itemIndex = itemIndexInCart(action.productId)
      if (itemIndex !== -1) {
        const product = { ...state.products[itemIndex] }
        product.chosen_sub_options = action.choices
        newCart.products = [
          ...state.products.slice(0, itemIndex),
          new Product({ ...product }),
          ...state.products.slice(itemIndex + 1)
        ]
      }
      return new Cart(newCart)
    }

    case 'UPDATE_COMMENTS': {
      const cart = { ...state }
      const newCart = cloneDeep(cart)
      const itemIndex = itemIndexInCart(action.productId)
      if (itemIndex !== -1) {
        const product = { ...state.products[itemIndex] }
        product.comments = action.comments
        newCart.products = [
          ...state.products.slice(0, itemIndex),
          new Product({ ...product }),
          ...state.products.slice(itemIndex + 1)
        ]
      }
      return new Cart(newCart)
    }

    default:
      return state
  }

  function itemIndexInCart(id: string) {
    return state.products.findIndex(item => {
      return item._id === id
    })
  }
}
