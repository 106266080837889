import HausmartService from './HausmartService'

class TermsService extends HausmartService {
  getVersion () {
    return this.api.get('/term')
      .catch(error => {
        throw new Error(error, 'version unavailable')
      })
  }

  getTerms () {
    return this.api.get('/term/latest')
      .catch(error => {
        throw new Error(error, 'terms unavailable')
      })
  }
}

export default new TermsService('/v2')
