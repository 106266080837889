import styled from 'styled-components/macro'
import { theme } from 'styled-tools'

export const CircularButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  color: ${theme('black')};
  transition: background-color 0.5s;
  cursor: pointer;
  background-color: ${theme('white')};
  &:hover {
    background-color: ${theme('lightGray')};
  }
`

export default CircularButton
