import React, { useState, useEffect, ReactElement } from 'react'
import CircleIconButton from '../../../ui/components/CircleIconButton'
import { MdArrowBack, MdMailOutline } from 'react-icons/md'
import { useHistory } from 'react-router'
import Logo from '../Logo/Logo'
import { LeftCol, RightCol, MenuWrapper, TopBarRow, LogoCol, Wrapper, ContactWrapper } from './TopBarStyledComponents'
import SearchBar from '../SearchBar/SearchBar'
import Navigation from '../Navigation/Navigation'
import { Notification } from 'react-notification-system'
import NavigationTile from '../Navigation/NavigationTile/NavigationTile'
import { useIntl } from 'react-intl'

interface Props {
  storeIdSection: boolean
  toggleGeolocationModal: () => void
  showNotification: (notification: Notification) => void
  backButton?: boolean
  backButtonAction?: () => void
  ActionButton?: () => ReactElement
  search: boolean
  opened: boolean
  storeSource?: StoreSource
  storeId?: string
  withLinks?: boolean
}

export default function TopBar({
  storeIdSection = false,
  toggleGeolocationModal,
  showNotification,
  backButton,
  backButtonAction,
  ActionButton,
  search = true,
  storeSource = 'HAUSMART',
  storeId,
  opened,
  withLinks = true
}: Props) {
  const history = useHistory()
  const [menu, setMenu] = useState(false)
  const intl = useIntl()

  useEffect(() => {
    setMenu(!!opened)
    if (opened) {
      setTimeout(() => {
        const searchInput = document.getElementById('search_bar-input')
        searchInput && searchInput.focus()
      }, 500)
    }
  }, [opened])

  return (
    <>
      <Wrapper>
        {menu && (
          <MenuWrapper>
            <SearchBar />
            <Navigation />
          </MenuWrapper>
        )}
        {!menu && (
          <TopBarRow>
            <LeftCol xs={{ size: 2 }}>
              {backButton && (
                <CircleIconButton
                  onClick={() => {
                    if (backButtonAction) {
                      backButtonAction()
                    } else {
                      history.goBack()
                    }
                  }}
                >
                  <MdArrowBack />
                </CircleIconButton>
              )}
            </LeftCol>
            <LogoCol xs={{ size: 8 }} md={{ size: 12 }} lg={{ size: 3 }} xl={{ size: 2, offset: 1 }}>
              {storeId !== '' && <Logo storeSource={storeSource} />}
            </LogoCol>
            <RightCol xs={{ size: 2 }}>{ActionButton && <ActionButton />}</RightCol>
            {/* {storeIdSection && (
              <EnterStoreBoxCol
                xs={{ size: '12' }}
                lg={{ size: 9 }}
                xl={{ size: 8 }}
              >
                <EnterStoreId
                  toggleGeolocationModal={toggleGeolocationModal}
                  history={history}
                  showNotification={showNotification}
                />
              </EnterStoreBoxCol>
            )} */}
          </TopBarRow>
        )}
      </Wrapper>
      {withLinks && (
        <ContactWrapper>
          <NavigationTile
            Icon={MdMailOutline}
            link="/contact"
            text={intl.formatMessage({ id: 'navigation.contact' })}
          />
        </ContactWrapper>
      )}
    </>
  )
}
