import Cookies from 'universal-cookie'
import { set } from '../actions/language'
import store from '../store'

const cookies = new Cookies()

const langInit = () => {
  // User's lang based on different language
  const navLang =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage
  // Remove region code if present
  const noRegionLang = navLang.replace(/-.*/, '')

  let lang = null

  switch (noRegionLang) {
    case 'es':
      lang = noRegionLang
      break
    default:
      lang = 'en'
  }
  store.dispatch(set(cookies.get('lang') || lang))
}

export default langInit
