import { CircleIcon } from './CircleIcons'
import styled from 'styled-components/macro'
import { theme } from 'styled-tools'

const CircleIconButton = styled(CircleIcon).attrs({
  width: '3rem',
  height: '3rem'
})`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: ${theme('white')};
  color: ${theme('black')};
  border: 0;
  cursor: pointer;
  &:hover {
    background: white;
    transform: translateY(2px);
  }

  svg {
    font-size: 20px;
  }
`

export default CircleIconButton
