function handleIntercomVisibility (location) {
  const locationMain = location.pathname.split('/')[1]
  const blacklist = ['paymentsuccess', 'paymenterror', 'store']
  const isInBlackListedPage = blacklist.includes(locationMain)
  isInBlackListedPage ? hideIntercom() : showIntercom()
}

function showIntercom () {
  if (window.intercomSettings && window.intercomSettings.hide_default_launcher === true) {
    window.intercomSettings.hide_default_launcher = false
    window.Intercom('update', {
      hide_default_launcher: false
    })
  }
}

function hideIntercom () {
  if (window.intercomSettings && window.intercomSettings.hide_default_launcher === false) {
    window.intercomSettings.hide_default_launcher = true
    window.Intercom('update', {
      hide_default_launcher: true
    })
  }
}

export default handleIntercomVisibility
