type Product = {
  is_stock_enabled: boolean,
  name: string,
  stock_quantity: number,
  _id: string
}

interface FilledCartProduct {
  _id: string,
  quantity: number,
  product: Product
}

interface Props {
  _id?: string,
  quantity?: number,
  product?: Product
}

class FilledCartProduct {
  constructor ({
    _id = '',
    quantity = 0,
    product = {
      is_stock_enabled: false,
      name: '',
      stock_quantity: 0,
      _id: ''
    }
  }: Props) {
    this._id = _id
    this.quantity = quantity
    this.product = product
  }

  getProductId () {
    return this.product?._id
  }

  isEnough () {
    return (
      this.product &&
      (!this.product.is_stock_enabled ||
        this.product.stock_quantity >= this.quantity)
    )
  }

  isNotAvailable () {
    return (
      this.product &&
      this.product.is_stock_enabled &&
      this.product.stock_quantity === 0
    )
  }

  isLowerStock () {
    return (
      this.product &&
      this.product.is_stock_enabled &&
      this.product.stock_quantity > 0 &&
      this.product.stock_quantity < this.quantity
    )
  }

  getMaxAvailableQuantity () {
    if (this.isNotAvailable()) {
      return 0
    }
    if (this.isEnough()) {
      return this.quantity
    }

    if (this.isLowerStock()) {
      return this.product?.stock_quantity
    }
  }
}

export default FilledCartProduct
