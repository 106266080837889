const setLatestVersion = latestVersion => ({
  type: 'SET_LATEST_VERSION',
  latestVersion
})

const setTerms = terms => ({
  type: 'SET_TERMS',
  terms
})

export { setTerms, setLatestVersion }
