import cloneDeep from 'lodash/cloneDeep'
import Store from '../../api/models/Store'
import {
  SET_ACTIVE_STORE,
  SET_STOCK_ENABLED,
  SET_PRODUCT_STOCK_ENABLED,
  SET_SHIPPING_ENABLED,
  SET_PRODUCT_SHIPPING_ENABLED,
  CLEAR_ACTIVE_STORE
} from '../../actions/activeStore'

const initialState = new Store()

type ActiveStoreActions =
  SET_ACTIVE_STORE |
  SET_STOCK_ENABLED |
  SET_PRODUCT_STOCK_ENABLED |
  SET_SHIPPING_ENABLED |
  SET_PRODUCT_SHIPPING_ENABLED |
  CLEAR_ACTIVE_STORE;

export default (state = initialState, action: ActiveStoreActions) => {
  switch (action.type) {
    case 'SET_ACTIVE_STORE': {
      const activeStore = new Store(action.store)
      return activeStore
    }

    case 'SET_STOCK_ENABLED': {
      const clonedStore = cloneDeep(state)
      clonedStore.setStockEnabled(action.status)
      return new Store(clonedStore)
    }

    case 'SET_PRODUCT_STOCK_ENABLED': {
      const clonedStore = cloneDeep(state)
      clonedStore.changeProductStockEnabled(action.productId, action.status)
      return new Store(clonedStore)
    }

    case 'SET_SHIPPING_ENABLED': {
      const clonedStore = cloneDeep(state)
      clonedStore.setShippingEnabled(action.status)
      return new Store(clonedStore)
    }

    case 'SET_PRODUCT_SHIPPING_ENABLED': {
      const clonedStore = cloneDeep(state)
      clonedStore.changeProductShippingEnabled(action.productId, action.status)
      return new Store(clonedStore)
    }

    case 'CLEAR_ACTIVE_STORE':
      return initialState

    default:
      return state
  }
}
