const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW':
      return action.notification

    case 'RESET':
      return null

    default:
      return null
  }
}
