import styled from 'styled-components/macro'
import { theme } from 'styled-tools'
import {
  ButtonDropdown as ButtonDropdownBase,
  DropdownToggle as DropdownToggleBase,
  DropdownMenu as DropdownMenuBase,
  DropdownItem as DropdownItemBase
} from 'reactstrap'

const ButtonDropdown = styled(ButtonDropdownBase)``

const DropdownToggle = styled(DropdownToggleBase)`
  background-color: transparent !important;
  fontsize: 0.9rem;
  cursor: pointer;
  border: none !important;
  box-shadow: none !important;
`

const DropdownMenu = styled(DropdownMenuBase)`
  border-radius: 5px !important;
  padding: 3px !important;
  background-color: ${theme('dark')}!important;
  min-width: 7rem !important;
  text-align: center !important;
`

const DropdownItem = styled(DropdownItemBase)`
  font-size: 0.9rem !important;
  border-radius: 2px !important;
  cursor: pointer;
  color: white !important;
  background-color: ${props =>
    props.selected ? theme('primary') : theme('dark')} !important;
`

export { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }
