const initalState = {
  shippingCost: null,
  subtotal: null,
  tax: null,
  totalAmount: null,
  apiError: null,
  serviceFee: null
}

export default (state = initalState, action) => {
  switch (action.type) {
    case 'SET_CALCULATION': {
      return { ...state, ...action.calculation, apiError: null }
    }

    case 'SET_CALCULATION_API_ERROR': {
      return {
        ...state,
        apiError: { [action.errorField]: action.errorMessage }
      }
    }
    default:
      return state
  }
}
