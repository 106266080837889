interface Props {
  message: string | string[],
  code: number,
  type: string,
  source?: StoreSource
}

interface StoreError {
  message: string,
  code: number,
  type: string,
  source: StoreSource
}

class StoreError extends Error {
  constructor ({ message, code, type, source }: Props) {
    super(Array.isArray(message) ? message[0] : message)
    this.message = Array.isArray(message) ? message[0] : message
    this.code = code
    this.type = type
    this.source = source || 'HAUSMART'
  }

  isNotGeo = () => {
    return this.type === 'NOT_GEO'
  }

  isNotInLocation = () => {
    return this.type === 'NOT_IN_LOCATION'
  }

  isNotFound = () => {
    return this.type === 'NOT_FOUND'
  }

  isPrivate = () => {
    return this.type === 'FORBIDDEN'
  }
}

export default StoreError
