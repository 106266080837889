import styled from 'styled-components/macro'
import { media } from '../../../ui/rwd'

const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  max-width: 100vw;
  flex-wrap: wrap;
  ${media.sm.down`
    justify-content: center;
  `}
`

export { NavigationWrapper }
