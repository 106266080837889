import { DEFAULT_LOCALE } from '../../i18n'

const initialState = {
  langCode: DEFAULT_LOCALE
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return { ...state, langCode: action.langCode }
    default:
      return state
  }
}
