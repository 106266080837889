import React from 'react'
import { BounceLoader } from 'react-spinners'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components/macro'
import { Container } from './OverlayLoaderStyledComponents'

const OverlayLoader = ({ loader = null, theme, onTimeout }) => {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onTimeout && onTimeout()
    }, 20000)
    return function cleanup() {
      clearTimeout(timeout)
    }
  }, [onTimeout])

  return <Container>{loader || <BounceLoader color={theme?.gray} show size={60} width={170} height={8} />}</Container>
}

OverlayLoader.propTypes = {
  onTimeout: PropTypes.func,
  theme: PropTypes.object,
  loader: PropTypes.func
}

export default withTheme(OverlayLoader)
export { OverlayLoader }
