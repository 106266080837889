import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import DynamicBreadcrumb from '../Breadcrumb/DynamicBreadcrumb'
import { useHistory } from 'react-router'
import TopBar from '../TopBar/TopBar'
import {
  Wrapper,
  ContentItem,
  BanerContainer
} from './NewHeaderStyledComponents'
import { Notification } from 'react-notification-system'

interface Props {
  fixedWidth?: boolean
  showBreadcrumb?: boolean
  breadcrumbTitle?: string
  storeIdSection?: boolean
  toggleGeolocationModal?: () => void
  showNotification: (notification: Notification) => void
  search?: boolean
  opened?: boolean
  children?: React.ReactElement
}

export default function NewHeader({
  fixedWidth = true,
  showBreadcrumb = true,
  breadcrumbTitle = '',
  storeIdSection = false,
  toggleGeolocationModal = () => {},
  showNotification,
  search = true,
  opened = false,
  children
}: Props) {
  const history = useHistory()
  return (
    <BanerContainer {...{fluid: !fixedWidth}}>
      <Row>
        <Col>
          <Wrapper id='baner-header' {...{contentProvided: !!children }}>
            <TopBar
              opened={opened}
              toggleGeolocationModal={toggleGeolocationModal}
              storeIdSection={storeIdSection}
              showNotification={showNotification}
              search={search}
            />
            {children && <ContentItem>{children}</ContentItem>}
          </Wrapper>
        </Col>
      </Row>
      <BreadcrumbsItem to={history.location.pathname}>
        {breadcrumbTitle}
      </BreadcrumbsItem>
      {showBreadcrumb && (
        <Row className='ml-1 justify-content-center'>
          <Container className='pt-2'>
            <Row>
              <Col />
              <Col md='10'>
                <span>
                  <DynamicBreadcrumb />
                </span>
              </Col>
              <Col />
            </Row>
          </Container>
        </Row>
      )}
    </BanerContainer>
  )
}
