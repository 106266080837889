import HausmartService from './HausmartService'
import Store from '../models/Store'
import store from '../../store'
import StoreError from '../StoreError'
import { setActiveStore } from '../../actions/activeStore'
import geoService from './geoService'
// import { clearCart } from '../../actions/cart'
import ReactGA from 'react-ga'
import Product from '../models/Product'
import samplePostalCodes from '../samplePostalCodes'
import _ from 'lodash'
import config from '../../config'

class StoreService extends HausmartService {
  async getStore (storeId, { notify = 'true', token = undefined, ct = null } = {}) {
    let storeData
    try {
      storeData = await this.getStoreWithoutLocation(storeId, { token, notify, ct })
    } catch (error) {
      if (error.code !== 400) {
        throw new StoreError(error)
      }
      // error 400 means that store exists but location is not correct (need to try with user location)
      try {
        storeData = await this.getStoreWithLocation(storeId, { token, notify, ct })
      } catch (error) {
        throw new StoreError({
          message: error.message || '',
          code: error.code || '',
          type: error.type || ''
        })
      }
    }
    if (storeData.store.source === 'ZEROTOUCH') {
      window.location.replace(`${config.ztRedirectUrl}/${storeId}`)
    }
    store.dispatch(setActiveStore(storeData.store))
    return new Store(storeData.store)
  }

  async getStoreWithoutLocation (storeId, { token = false, notify, ct }) {
    const [latitude, longitude] = [0, 0]
    const storeUrl =
      `/v2/store/${storeId}?${this.encodeQueryData({ token, latitude, longitude, notify, ct })}`
    try {
      const storeData = await this.getStoreData(storeUrl)
      return storeData
    } catch (error) {
      let type
      switch (error.code) {
        case 400:
          type = 'NO_LOCATION_FAILED'
          break
        case 404:
          type = 'NOT_FOUND'
          break
        case 403:
          type = 'FORBIDDEN'
          break
        default:
          type = 'UNDEFINED'
      }

      throw new StoreError({
        message: error.message,
        code: error.code,
        type,
        source: error.source
      })
    }
  }

  async getStoreWithLocation (storeId, { token = false, notify = true, ct }) {
    let coordinates
    try {
      const coordsResp = await geoService.getCoords()
      coordinates = coordsResp.coords
    } catch (error) {
      throw new StoreError({
        message: 'This store is georestricted. Cannot get user location.',
        code: 418,
        type: 'NOT_GEO'
      })
    }

    try {
      const { latitude, longitude } = coordinates
      const storeUrl =
        `/v2/store/${storeId}?${this.encodeQueryData({ token, latitude, longitude, notify, ct })}`
      const storeData = await this.getStoreData(storeUrl)
      return storeData
    } catch (error) {
      if (error instanceof StoreError) {
        throw error
      }
      throw new StoreError({
        message: error.message[0],
        code: 400,
        type: 'NOT_IN_LOCATION'
      })
    }
  }

  async getStoreData (storeUrl) {
    let resp
    try {
      resp = await this.api.get(storeUrl)
    } catch (err) {
      throw new StoreError({
        message: err.response.data.response,
        code: err.response.status,
        type: '',
        source: err.response.data.source
      })
    }
    if (_.isNil(resp.store)) {
      throw new StoreError({
        message: 'recieved response without store node',
        code: 400,
        type: 'NO_STORE_NODE'
      })
    }
    return { store: new Store(resp.store) }
  }

  async getCost (storeId, items = [], storeGeometry, shipping, ct = null, table_number = '', is_table_pickup = false) {
    if (items.length <= 0) {
      return {
        shipping_cost: 0,
        subtotal: 0,
        tax: 0,
        total_amount: 0
      }
    }

    const { latitude, longitude } = storeGeometry
    if (shipping) {
      shipping.line_1 = shipping.line_1 || 'empty'
      if (shipping.postal_code === '' && shipping.country_code === 'US') {
        const stateInfo = samplePostalCodes.find(
          item => item.code === shipping.state
        )
        if (stateInfo) {
          shipping.postal_code = stateInfo.postal_code
        }
      }
    }

    try {
      const resp = await this.api.post(
        `/v2/shipping/calculate/${storeId}`,
        {
          items: items.map(item =>
            Object.assign({}, item, { id: item._id, quantity: item.count })
          ),
          location: { latitude: latitude, longitude: longitude },
          payment_origin: 'WEB',
          shipping,
          ct,
          intermediate: shipping === null ? true : undefined,
          table_number,
          is_table_pickup
        }
      )
      return resp
    } catch (err) {
      throw new StoreError({
        message: err.response.data.response,
        code: err.response.status,
        type: '',
        source: err.response.data.source
      })
    }
  }

  /**
   * @param { {token, latitude, longitude, notify} } data (
   * @returns {string} encoded string like 'param=value&param2=value2...'
   */

  encodeQueryData (data) {
    const ret = []
    for (const d in data) {
      if (!_.isNil(data[d])) {
        if (d === 'notify') {
          ret.push('enter=' + encodeURIComponent(data[d]))
        } else if (d === 'token' && data[d] === false) {
          continue
        } else {
          ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
        }
      }
    }
    return ret.join('&')
  }

  openViaBranch ({ storeId, storeSource, ...linkParams }) {
    const urlEnd = this.encodeQueryData(linkParams)

    ReactGA.event({
      category: 'link',
      action: 'open_via_branch',
      label: linkParams ? linkParams.storeId : 'empty'
    })

    window.location.href = `https://${storeSource === 'ZEROTOUCH' ? 'gozerotouch' : 'hausmart'}.app.link?storeId=${storeId}&${urlEnd}`
  }

  getShippingCountries (storeId, items = [], storeGeometry, ct, table_number = '', is_table_pickup = false) {
    const { latitude, longitude } = storeGeometry

    return this.api
      .post(`/v2/shipping/destinations/${storeId}`, {
        items: items.map(item =>
          Object.assign({}, item, { id: item._id, quantity: item.count })
        ),
        location: { latitude: latitude, longitude: longitude },
        payment_origin: 'WEB',
        ct: ct,
        table_number,
        is_table_pickup
      })
      .catch(err => {
        throw new StoreError({
          message: err.response.data.response,
          code: err.response.status,
          type: '',
          source: err.response.data.source
        })
      })
  }

  getCountryStates (countryCode) {
    countryCode = countryCode.toLowerCase()
    return this.api
      .get(`/v2/shipping/country/${countryCode}/states`)
      .catch(err => {
        if (err) {
          throw new Error(err)
        }
      })
  }

  getClaimStatus (storeId) {
    return this.api
      .get(`/v2/claim/${storeId}`)
      .then(resp => {
        return resp
      })
      .catch(err => {
        if (err) {
          throw new Error(err)
        }
      })
  }

  getStoreQr (storeId, format, textId, inHaus) {
    return this.api
      .get(
        `/qr?storeId=${storeId}&format=${format}&text=${textId}&house=${inHaus}&square=${textId &&
        !inHaus}`,
        {
          responseType: 'blob'
        }
      )
      .then(resp => {
        return resp
      })
      .catch(err => {
        if (err) {
          throw new Error(err)
        }
      })
  }

  getPop (storeId, template) {
    return this.api
      .get(`/pop?storeId=${storeId}&template=${template}`, {
        responseType: 'blob'
      })
      .then(resp => {
        return resp
      })
      .catch(err => {
        if (err) {
          throw new Error(err)
        }
      })
  }

  getStoreMeta (storeId) {
    return this.api.get(`/v2/store/${storeId}/meta`)
  }

  reportStore (id, reason, deviceId) {
    return this.api.post(`/v2/store/${id}/report`, {
      reason,
      device_id: deviceId
    })
  }

  getProduct (storeId, productId, ct = null, { token } = { token: undefined }) {
    return this.getProductWithoutLocation(storeId, productId, { token: token }, ct)
      .catch(error => {
        // error 400 means that store exists but location is not correct (need to try with user location)
        if (error.code !== 400) {
          throw new StoreError(error)
        }
        return this.getProductWithLocation(storeId, productId, { token }, ct)
          .then(productData => {
            return productData
          })
          .catch(error => {
            throw new StoreError({
              message: error.message || '',
              code: error.code || '',
              type: error.type || ''
            })
          })
      })
  }

  getProductData (storeUrl) {
    return this.api
      .get(storeUrl)
      .then(resp => {
        if (resp.product) {
          return new Product(resp.product)
        } else {
          throw new StoreError({
            message: 'recieved response without product node',
            code: 400,
            type: 'NO_PRODUCT_NODE'
          })
        }
      })
      .catch(err => {
        throw new StoreError({
          message: err.response.data.response,
          code: err.response.status,
          type: '',
          source: err.response.data.source
        })
      })
  }

  getProductWithoutLocation (storeId, productId, { token } = { token: undefined }, ct = null) {
    const [latitude, longitude] = [0, 0]
    const storeUrl =
      `/v2/store/${storeId}/products/${productId}?` +
      this.encodeQueryData({ token, latitude, longitude, ct })
    return this.getProductData(storeUrl).catch(error => {
      let type = 'UNDEFINED'
      if (error.code === 400) type = 'NO_LOCATION_FAILED'
      if (error.code === 404) type = 'NOT_FOUND'
      if (error.code === 403) type = 'FORBIDDEN'

      // temporary fix for messages in array
      if (error.code === 400 || error.code === 403) { error.message = error.message[0] }
      // should be removed when api change (same case in other requests)

      throw new StoreError({
        message: error.message,
        code: error.code,
        type,
        source: error.source
      })
    })
  }

  getProductWithLocation (storeId, productId, { token } = { token: undefined }, ct = null) {
    return new Promise((resolve, reject) => {
      geoService
        .getCoords()
        .then(coordsResp => {
          const { latitude, longitude } = coordsResp.coords
          const storeUrl =
            `/v2/store/${storeId}/products/${productId}?` +
            this.encodeQueryData({ token, latitude, longitude, ct })
          this.getProductData(storeUrl)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(
                new StoreError({
                  message: error.message[0],
                  code: 400,
                  type: 'NOT_IN_LOCATION'
                })
              )
            })
        })
        .catch(() => {
          reject(
            new StoreError({
              message: 'product is georestircited and cannot get user geo',
              code: 418,
              type: 'NOT_GEO'
            })
          )
        })
    })
  }
}

export default new StoreService()
