import Cookies from 'universal-cookie'

const cookies = new Cookies()
const hostname = window.location.hostname

export type CookieData = {
  isAccepted: boolean,
  domain: string,
  date: Date,
  version: string
}

class GdprCookiesService {
  name = 'hm-gdpr'

  accept (version: string) {
    const expirationDate = new Date()
    expirationDate.setMonth(expirationDate.getMonth() + 3)
    cookies.set(
      this.name,
      { isAccepted: true, domain: hostname, date: new Date(), version },
      {
        path: '/',
        domain: hostname,
        expires: expirationDate
      }
    )
    const csconsentcheckbox = document.getElementById('csconsentcheckbox')
    if (csconsentcheckbox instanceof HTMLInputElement) {
      csconsentcheckbox.checked = true
    }
  }

  decline (version:string) {
    cookies.set(
      this.name,
      { isAccepted: false, domain: hostname, date: new Date(), version },
      { path: '/', domain: hostname }
    )
    const csconsentcheckbox = document.getElementById('csconsentcheckbox')
    if (csconsentcheckbox instanceof HTMLInputElement) {
      csconsentcheckbox.checked = false
    }
  }

  get (): CookieData {
    return cookies.get(this.name)
  }
}

export default new GdprCookiesService()
