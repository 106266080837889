import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

const ScrollToTop = React.memo(({ location, children }) => {
  React.useEffect(() => {
    const termstab = location.pathname.indexOf('/terms/') === 0
    if (!termstab) {
      window.scrollTo(0, 0)
    }
  }, [location])
  return children
})

ScrollToTop.displayName = 'ScrollToTop'

ScrollToTop.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.array
}

export default withRouter(ScrollToTop)
export { ScrollToTop }
