import HausmartService from './HausmartService'

class ClaimService extends HausmartService {
  getClaimCodeByStoreId (storeId) {
    return this.api.get(`/${storeId}/invite`)
  }

  sendCodeViaEmail (storeId, email, code) {
    return this.api.post(`/${storeId}/invite/${code}`, { email: email })
  }

  requestNewStore (email) {
    return this.api.post('', {
      email: email,
      short_id: '_',
      claimant_type: 'OTHER',
      campaign: 'HOMEP'
    })
  }
}

const claimService = new ClaimService('/v2/claim_request')

export default claimService
