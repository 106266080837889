import reducers from './redux/reducers'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import localStorageService from './api/services/localStorageService'
import Store from './api/models/Store'
import Cart from './api/models/Cart'
import { initialClaimCodes } from './redux/reducers/claimCodes'

let initialCart = localStorageService.getCart()
initialCart = initialCart ? new Cart(initialCart) : new Cart()
const initialStore = new Store()
const cookies: Cookies = { accepted: true, version: 'v0_0' }

// remove store from local storage if exists.
localStorageService.removeStore()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducers,
  {
    cart: initialCart,
    activeStore: initialStore,
    cookies,
    claimCodes: initialClaimCodes
  },
  composeEnhancers(applyMiddleware(thunk))
)

store.subscribe(() => {
  const currentStore = store.getState()
  localStorageService.setCart(currentStore.cart)
})

export default store
