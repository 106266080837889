import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../../components/layout/Logo/Logo'
import {
  Wrapper,
  LogoWrapper,
  ContentWrapper,
  Flamingo,
  Cups,
  Cactus,
  Ball,
  Heading
} from './NotFoundStyledComponents'
import { FormattedMessage } from 'react-intl'
import { Button } from 'reactstrap'

const NotFound = React.memo(({ history }) => (
  <Wrapper>
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
    <ContentWrapper>
      <Heading>Hmmm...</Heading>
      <h5>
        <FormattedMessage id='notFound.weCouldntFind' />
      </h5>
      <Button color='primary' onClick={() => history.push('/')}>
        <FormattedMessage id='notFound.goToHausmart' />
      </Button>
    </ContentWrapper>
    <div />
    <div />
    <Flamingo />
    <Cups />
    <Cactus />
    <Ball />
  </Wrapper>
))

NotFound.displayName = 'NotFound'

NotFound.propTypes = {
  history: PropTypes.object
}

export default NotFound
