/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
import omitBy from 'lodash/omitBy'

import enTranslationMessages from './translations/en.json'
import esTranslationMessages from './translations/es.json'
import isTranslationMessages from './translations/is.json'

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill')
  require('@formatjs/intl-pluralrules/dist/locale-data/en') // Add locale data for en
  require('@formatjs/intl-pluralrules/dist/locale-data/es') // Add locale data for es
  require('@formatjs/intl-pluralrules/dist/locale-data/is') // Add locale data for is
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill')
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en') // Add locale data for en
  require('@formatjs/intl-relativetimeformat/dist/locale-data/es') // Add locale data for es
  require('@formatjs/intl-relativetimeformat/dist/locale-data/is') // Add locale data for is
}

export const DEFAULT_LOCALE = 'en'

// prettier-ignore
export const appLocales = [
  'en',
  'es',
  'is'
]

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages = enTranslationMessages
  return Object.assign(
    {},
    defaultFormattedMessages,
    omitBy(messages, ref => ref === '')
  )
}

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  es: formatTranslationMessages('es', esTranslationMessages),
  is: formatTranslationMessages('is', isTranslationMessages)
}
