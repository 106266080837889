import styled, { css } from 'styled-components/macro'
import { theme } from 'styled-tools'
import { media } from '../../../ui/rwd'

export const SearchBarWrapper = styled.div`
  display: flex;
  flex: 1;
  max-width: 45%;
  ${media.md.down`
    justify-content: center;
    flex: 0;
    width: 100%;
    margin: 1em 0;
    max-width: 100%;
  `}
`

export const InputWrapper = styled.div`
  position: relative;
  width: 90%;
  max-height: 50px;
  z-index: 5;
`

export const ResultsList = styled.div`
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.3);
`

export const Result = styled.div`
  height: 4rem;
  width: 100%;
  background-color: ${theme('white')};
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: ${theme('black')};
  transition: background-color 0.5s;
  padding: 0.5em 1em;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &.hovered {
    background-color: ${theme('lightGray')};
  }
`

const Ellipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ResultDescription = styled.span`
  width: 75%;
  font-size: 0.6em;
  color: ${theme('gray')};
  ${Ellipsis};
`

export const ResultTitle = styled.span`
  width: 90%;
  ${Ellipsis};
  text-transform: uppercase;
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`
