import AxiosProvider from './AxiosProvider'
import config from '../config'
import store from '../store'
import {
  incrementRequestCount,
  decrementRequestCount
} from '../actions/requests'

class CoreApi {
  constructor (baseUrl = config.apiBaseUrl) {
    this.api = AxiosProvider(baseUrl)
    this.baseUrl = baseUrl

    this._afterResponse = this._afterResponse.bind(this)
    this.setInterceptors(
      this._beforeRequest,
      this._requestError,
      this._afterResponse,
      this._responseError
    )
  }

  setInterceptors (beforeRequest, requestError, afterResponse, responseError) {
    this.api.interceptors.request.use(beforeRequest, requestError)
    this.api.interceptors.response.use(afterResponse, responseError)
  }

  setEndpointUrl (url) {
    this.api.defaults.baseURL = this.baseUrl + '/' + url
  }

  _beforeRequest (config) {
    const state = store.getState()
    config.headers['Accept-Language'] = `${
      state.language.langCode
    }, en-US;q=0.5, en;q=0.4`
    store.dispatch(incrementRequestCount())
    return config
  }

  _requestError (error) {
    store.dispatch(decrementRequestCount())
    throw error
  }

  _afterResponse (resp) {
    store.dispatch(decrementRequestCount())
    return resp.data || resp
  }

  _responseError (error) {
    store.dispatch(decrementRequestCount())
    if (!error.response) {
      error.response = {
        status: 502,
        data: {
          response:
            'Our servers are misbehaving. Please try again in a few minutes.'
        }
      }
    }
    throw error
  }
}

export default CoreApi
