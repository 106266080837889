import { Theme } from './types'

const defaultTheme: Theme = {
  name: 'default',
  _id: 'default',
  // STORE THEME DEFINITIONS,
  white: '#fff',
  primary: '#57bdbd',
  secondary: '#ef509c',
  hyperlink: '#8B008B',
  success: '#00A96B',
  info: '#17a2b8',
  warning: '#fd7e14',
  danger: '#ef4634',
  light: '#dfe1e8',
  dark: '#434345',
  yellow: '#f9de6b',
  pink: '#ef509c',
  gray: '#434345',
  lightGray: '#dfe1e8',
  lightenGray: '#f1f3f7',
  seashell: '#F1F1F1',
  mineShaft: '#2e2e2e',
  black: '#1e1b1c',
  baseFontSize: '1.25rem',
  // STORE THEME DEFINITIONS
  cover_photo:
    'https://trello-attachments.s3.amazonaws.com/5be5b6384c8150553f3a75cb/5bf5bb863f878a6fe341bb7f/d3d5ca807025ee8c5ed8be90bd6a4d0f/HM-Theme-Art-Ski.png',
  burger: '#1E1B1C',
  storeName: '#1E1B1C',
  dotMenu: '#1E1B1C',
  exit: '#1E1B1C',
  profileImageBorder: '#9EDAE4',
  storeIdBox: '#9EDAE4',
  storeIdText: '#1E1B1C',
  storeMessageText: '#1E1B1C',
  categoryBox: '#9EDAE4',
  categoryText: '#1E1B1C',
  categoryLine: '#9EDAE4',
  productBoxBackground: '#FFFFFF',
  productName: '#1E1B1C',
  productPrice: '#1E1B1C',
  productPlusButtonBackground: '#9EDAE4',
  productPlusButtonIcon: '#F1F3F7',
  productMinusButtonBackground: '#9EDAE4',
  productMinusButtonIcon: '#F1F3F7',
  shippingIcon: '#1E1B1C',
  quantityBox: '#9EDAE4',
  quantityText: '#F1F3F7',
  stockBannerBackground: '#9EDAE4',
  stockBannerText: '#F1F3F7',
  productMultipleImagesBox: '#FFFFFF',
  productMultipleImagesIcon: '#1E1B1C',
  productMultipleImagesText: '#1E1B1C',
  soldOutBannerBackground: '#9EDAE4',
  soldOutBannerText: '#F1F3F7',
  selectorBg: '#EC6964',
  checkoutButtonBackground: '#9EDAE4',
  checkoutButtonText: '#1E1B1C',
  clearCartButtonBackground: '#9EDAE4',
  clearCartButtonText: '#1E1B1C',
  clearCartButtonBorder: '#F1F3F7',
  storeBackgroundColor: '#F1F3F7'
}
export default defaultTheme
