import styled from 'styled-components/macro'
import { Container, Row } from 'reactstrap'

const Layout = styled(Container).attrs(props => ({
  fluid: true
}))`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Content = styled(Row)`
  flex: 1;
  display: flex;
  flex-direction: row;
`

export { Layout, Content }
