/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'

const LanguageProvider = ({ lang, messages, children }) => {
  return (
    <IntlProvider locale={lang} key={lang} messages={messages[lang]}>
      {React.Children.only(children)}
    </IntlProvider>
  )
}

LanguageProvider.propTypes = {
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
  lang: PropTypes.oneOf(['es', 'en']).isRequired
}

const mapStateToProps = state => ({
  lang: state.language.langCode
})

export default connect(mapStateToProps)(LanguageProvider)
