import React from 'react'
import { Col } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import ScrollToTop from '../../common/ScrollToTop'
import 'animate.css/animate.min.css'
import { Container, Content, Main } from './RawLayoutStyledComponents'
import { ThemeProvider } from 'styled-components/macro'
import { defaultTheme } from '../../../ui'
import FaviconImage from '../../../assets/icons/favicon-white.png'
import Favicon from 'react-favicon'
import PropTypes from 'prop-types'

class RawLayout extends React.PureComponent {
  render () {
    const { children } = this.props
    return (
      <ThemeProvider theme={defaultTheme}>
        <ScrollToTop>
          <Favicon url={FaviconImage} />
          <Container>
            <Col>
              <Content>
                <Main>
                  {children &&
                    React.cloneElement(children, this.props)}
                </Main>
              </Content>
            </Col>
          </Container>
        </ScrollToTop>
      </ThemeProvider>
    )
  }
}

RawLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
}

export default withRouter(RawLayout)
export { RawLayout }
