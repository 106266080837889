const incrementRequestCount = () => {
  return {
    type: 'INCREMENT'
  }
}

const decrementRequestCount = () => {
  return {
    type: 'DECREMENT'
  }
}

const clearRequestCount = () => {
  return {
    type: 'CLEAR'
  }
}

export { incrementRequestCount, decrementRequestCount, clearRequestCount }
