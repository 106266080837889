import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import cart from './cart'
import notification from './notification'
import requests from './requests'
import cookies from './cookies'
import product from './product'
import activeStore from './activeStore'
import language from './language'
import checkout from './checkout'
import terms from './terms'
import claimCodes from './claimCodes'

export default combineReducers({
  routing: routerReducer,
  cart,
  notification,
  requests,
  cookies,
  activeStore,
  language,
  product,
  checkout,
  terms,
  claimCodes
})
