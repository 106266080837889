import localStorageService from './localStorageService'
import store from '../../store'
import {
  decrementRequestCount,
  incrementRequestCount
} from '../../actions/requests'

let geo = null

class GeoService {
  getCoords () {
    store.dispatch(incrementRequestCount())
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          return navigator.geolocation.getCurrentPosition(
            geoResp => {
              geo = this.parseGeoObject(geoResp)
              localStorageService.setGeo(geo)
              store.dispatch(decrementRequestCount())
              return resolve(geo)
            },
            error => {
              store.dispatch(decrementRequestCount())
              return reject(new Error(error.message))
            },
            {
              // maximumAge:600000, default is 0ms that means the position must be live
              // enableHighAccuracy: false // propable we dont need highAccurancy but
              timeout: 8000
            }
          )
        } catch (error) {
          store.dispatch(decrementRequestCount())
          return reject(new Error('no naviagator'))
        }
      }, 500)
    })
  }

  parseGeoObject (geo) {
    return {
      timestamp: geo.timestamp,
      coords: {
        latitude: geo.coords.latitude,
        longitude: geo.coords.longitude
      }
    }
  }
}

export default new GeoService()
