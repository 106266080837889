import gdprCookiesService from '../../api/services/gdprCookiesService'

const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ACCEPT_COOKIE':
      gdprCookiesService.accept(state.version)
      return { accepted: true, version: state.version }

    case 'DISAGREE_COOKIE':
      gdprCookiesService.decline(state.version)
      return { accepted: false, version: state.version }

    case 'UNSET_COOKIE':
      return { accepted: null, version: state.version }

    case 'UPDATE_COOKIE':
      return { accepted: action.accepted, version: action.version }

    default:
      return state
  }
}
