import { Container as cont } from 'reactstrap'
import styled from 'styled-components/macro'
import { media } from '../../../ui/rwd'

const OuterContainer = styled.div`
  background-color: #222222;
  min-height: 100vh;
  height: 100%;
  padding: 15px 0;
  ${media.sm`
   padding: 2rem 0;
 `}
`
const Container = styled(cont).attrs(props => ({
  fluid: false
}))`
  flex: 1;
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  background-color: white;
  padding: 0 15px;
`

export { Container, Content, OuterContainer }
