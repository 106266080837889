import isString from 'lodash/isString'

function simpleHash (s) {
  /* Simple hash function. */
  var a = 1
  var c = 0
  var h
  var o
  if (s) {
    a = 0
    /* jshint plusplus:false bitwise:false */
    for (h = s.length - 1; h >= 0; h--) {
      o = s.charCodeAt(h)
      a = ((a << 6) & 268435455) + o + (o << 14)
      c = a & 266338304
      a = c !== 0 ? a ^ (c >> 21) : a
    }
  }
  return a
}

function mockImagesWithID (images) {
  let mocked = []
  if (isString(images[0])) {
    images.forEach((o, i, a) => {
      mocked.push({
        image_id: simpleHash(o),
        url: o
      })
    })
  } else {
    mocked = images
  }

  return mocked
}

export default mockImagesWithID
