import React from 'react'
import { FormattedMessage } from 'react-intl'
import NewHeader from '../layout/NewHeader/NewHeader'
import styled from 'styled-components/macro'

const Title = styled.h5`
  text-align: center;
`

const BrowserNotSupported = () => {
  return (
    <NewHeader key='header' showBreadcrumb={false}>
      <Title>
        <FormattedMessage
          id='general.BrowserNotSupported'
          defaultMessage='Your browser is not supported. Download and use modern web browser.'
        />
      </Title>
    </NewHeader>
  )
}

export default BrowserNotSupported
