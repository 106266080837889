import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  Container,
  Header,
  Logo,
  ClaimInfoBox,
  QrCode,
  ClaimDescription,
  ClaimLink,
  ClaimCode,
  GenerateButton,
  ShareContener,
  FormGroup,
  Label,
  Input,
  SuccessMessage,
  RefreshClaimLink,
  ChannelContainer,
  SocialButtonsContainer,
  ButtonUppercase,
  CenterContainer,
  InfoMessage,
  ErrorMessage
} from './ShareCodesStyledComponents'
import logo from '../../assets/svg/Hausmart_logo_black.svg'
import logoHausmart from '../../assets/images/hausmart-logo-black.png'
import { parse } from 'query-string'

import { MdCheck, MdContentCopy, MdRefresh } from 'react-icons/md'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share'
import getHostUrl from '../../helpers/getHostUrl'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { BounceLoader } from 'react-spinners'
import posed, { PoseGroup } from 'react-pose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as claimCodesActions from '../../actions/claimCodes'
import {
  getActiveCode,
  getActiveQrCode,
  getFreeCodes,
  getStatus,
  getStatusDescription,
  isActiveCodeUsed,
  isAnyUnusedLeft
} from '../../redux/selectors/sharedCodes'

const AnimatedContainer = posed(Container)({
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 150,
      damping: 15,
      delay: 100
    }
  },

  hidden: {
    opacity: 0,
    y: 100
  }
})

const AnimatedContainerTop = posed.div({
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 150,
      damping: 15,
      delay: 10
    }
  },

  hidden: {
    opacity: 0,
    y: -100
  }
})

class ShareCodes extends React.PureComponent {
  constructor (props) {
    super(props)
    const { location } = props
    const { fromId, toId } = parse(location.search)
    this.storeId = fromId
    this.state = {
      idToClaim: parse(props.location.search).toId,
      availableClaimIds: [],
      emailField: '',
      emailError: false,
      copied: false
    }
    this.storeId && props.claimCodesActions.fetchClaimCodes(this.storeId, toId)
  }

  sendViaEmail = e => {
    e.preventDefault()
    this.props.claimCodesActions
      .sendCodeViaEmail(this.storeId, this.state.emailField)
      .catch(error => {
        this.setState({ emailError: error })
      })
  }

  retrySendViaEmail = e => {
    this.setState({ emailError: false })
    this.sendViaEmail(e)
  }

  copyToClipart = () => {
    this.setState({ copied: true })
    setTimeout(() => {
      this.setState({ copied: false })
    }, 5000)
  }

  handleChange = el => {
    this.setState({ emailField: el.target.value })
  }

  handleFocus = el => {
    el.target.select()
  }

  generateNextClaimId = () =>
    this.props.claimCodesActions.generateNextActiveCode()

  markCodeAsUnused = () =>
    this.props.claimCodesActions.markCodeAsUnused(this.props.activeCode)

  generateNextClaimIdAndMarkAsUnused = () =>
    this.props.claimCodesActions.generateNextActiveCodeAndMarkAsUnused()

  render () {
    const {
      history,
      activeCode,
      activeQrCode,
      codes,
      isActiveCodeUsed,
      isAnyUnusedLeft,
      status,
      statusDescription
    } = this.props
    const { emailError, emailField } = this.state
    const shareLink = `${getHostUrl()}/link?storeId=${activeCode}`
    return (
      <React.Fragment>
        <Header>
          <AnimatedContainerTop
            key='content'
            initialPose={'hidden'}
            pose={status && 'visible'}
          >
            <Logo src={logo} onClick={() => history.push('/home')} alt="Logo" />
          </AnimatedContainerTop>
        </Header>
        <AnimatedContainer
          key='content'
          initialPose={'hidden'}
          pose={status === 'SUCCESS' && 'visible'}
        >
          {status === 'SUCCESS' && (
            <React.Fragment>
              <ClaimInfoBox>
                <QrCode src={activeQrCode} />
                <ClaimDescription>
                  <ClaimCode>{activeCode}</ClaimCode>
                  <ClaimLink href=''>{shareLink}</ClaimLink>
                  {codes.size > 1 ? (
                    <GenerateButton
                      color='secondary'
                      size='sm'
                      onClick={this.generateNextClaimId}
                    >
                      <MdRefresh size='1.5em' />{' '}
                      <FormattedMessage id='shareCodes.getAnotherCode' />
                    </GenerateButton>
                  ) : null}
                </ClaimDescription>
              </ClaimInfoBox>
              <ShareContener>
                <h3>
                  <FormattedMessage id='shareCodes.shareHasmartStoreId' />
                </h3>
                <ChannelContainer>
                  <p>
                    <FormattedMessage id='shareCodes.InviteFriend' />
                  </p>
                  <Label htmlFor='examplePassword'>
                    1. <FormattedMessage id='shareCodes.sendViaEmail' />
                  </Label>
                  <FormGroup onSubmit={this.sendViaEmail}>
                    <Input
                      type='email'
                      onChange={this.handleChange}
                      value={emailField}
                      required
                      name='email'
                      id='examplePassword'
                      placeholder='email to invite'
                    />
                    <ButtonUppercase size='sm' color='secondary'>
                      <FormattedMessage id='shareCodes.sendButton' />
                    </ButtonUppercase>
                  </FormGroup>
                  <PoseGroup>
                    {isActiveCodeUsed && !emailError && (
                      <SuccessMessage key='successMessage'>
                        <FormattedMessage
                          id='shareCodes.emailWithCodeSentSuccessful'
                          values={{ code: activeCode }}
                        />
                        {isAnyUnusedLeft && (
                          <React.Fragment>
                            ,{' '}
                            <RefreshClaimLink
                              onClick={this.generateNextClaimId}
                            >
                              <FormattedMessage id='shareCodes.generateAnother' />
                            </RefreshClaimLink>{' '}
                            ?
                          </React.Fragment>
                        )}
                      </SuccessMessage>
                    )}
                    {isActiveCodeUsed && !emailError && !isAnyUnusedLeft && (
                      <InfoMessage key='infoMessage'>
                        <FormattedMessage
                          id='shareCodes.areYouSureOnceMore'
                          values={{ code: activeCode }}
                        />
                        <br />
                        <RefreshClaimLink onClick={this.markCodeAsUnused}>
                          <FormattedMessage id='shareCodes.useItOnceMore' />
                        </RefreshClaimLink>
                        &nbsp;
                        <FormattedMessage id='general.or' />
                        &nbsp;
                        <RefreshClaimLink
                          onClick={this.generateNextClaimIdAndMarkAsUnused}
                        >
                          <FormattedMessage id='shareCodes.generateAnother' />
                        </RefreshClaimLink>
                      </InfoMessage>
                    )}
                    {emailError && (
                      <ErrorMessage key='infoMessage'>
                        {emailError}
                        <RefreshClaimLink onClick={this.retrySendViaEmail}>
                          <FormattedMessage id='shareCodes.sentOnceMore' />
                        </RefreshClaimLink>
                      </ErrorMessage>
                    )}
                  </PoseGroup>
                </ChannelContainer>
                <ChannelContainer>
                  <Label htmlFor='examplePassword'>
                    2. <FormattedMessage id='shareCodes.socialShare' />
                  </Label>
                  <SocialButtonsContainer>
                    <FacebookShareButton
                      url={shareLink}
                      className='share-buttons-web__button'
                    >
                      <FacebookIcon size={32} />
                    </FacebookShareButton>
                    <LinkedinShareButton
                      url={shareLink}
                      className='share-buttons-web__button'
                    >
                      <LinkedinIcon size={32} />
                    </LinkedinShareButton>
                    <TwitterShareButton
                      url={shareLink}
                      className='share-buttons-web__button'
                    >
                      <TwitterIcon size={32} />
                    </TwitterShareButton>
                    <PinterestShareButton
                      url={shareLink}
                      media={logoHausmart}
                      className='share-buttons-web__button'
                      alt='Pinterest share'
                    >
                      <PinterestIcon size={32} />
                    </PinterestShareButton>
                  </SocialButtonsContainer>
                </ChannelContainer>
                <ChannelContainer>
                  <Label htmlFor='examplePassword'>
                    3. <FormattedMessage id='shareCodes.copyLink' />
                  </Label>
                  <FormGroup onSubmit={e => e.preventDefault()}>
                    <Input
                      type='text'
                      value={shareLink}
                      onFocus={this.handleFocus}
                      required
                      name='email'
                      id='examplePassword'
                      placeholder='email to invite'
                    />
                    {!this.state.copied ? (
                      <CopyToClipboard
                        text={shareLink}
                        onCopy={this.copyToClipart}
                      >
                        <ButtonUppercase size='sm' color='secondary'>
                          <MdContentCopy size='1.5em' />
                          COPY
                        </ButtonUppercase>
                      </CopyToClipboard>
                    ) : (
                      <ButtonUppercase size='sm' color='success'>
                        <MdCheck size='1.5em' /> COPIED!
                      </ButtonUppercase>
                    )}
                  </FormGroup>
                </ChannelContainer>
              </ShareContener>
            </React.Fragment>
          )}
        </AnimatedContainer>
        {(status === 'IN_PROGRESS' || !status) && (
          <CenterContainer>
            <BounceLoader color='black' show size={60} width={170} height={8} />
          </CenterContainer>
        )}
        {status === 'ERROR' && (
          <CenterContainer>{statusDescription}</CenterContainer>
        )}
      </React.Fragment>
    )
  }
}

ShareCodes.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  claimCodesActions: PropTypes.object,
  activeCode: PropTypes.string,
  activeQrCode: PropTypes.string,
  codes: PropTypes.object,
  isActiveCodeUsed: PropTypes.bool,
  isAnyUnusedLeft: PropTypes.bool,
  status: PropTypes.string,
  statusDescription: PropTypes.string
}

const mapStateToProps = state => ({
  codes: getFreeCodes(state),
  activeCode: getActiveCode(state),
  activeQrCode: getActiveQrCode(state),
  isActiveCodeUsed: isActiveCodeUsed(state),
  isAnyUnusedLeft: isAnyUnusedLeft(state),
  status: getStatus(state),
  statusDescription: getStatusDescription(state)
})

const mapDispatchToProps = dispatch => ({
  claimCodesActions: bindActionCreators(claimCodesActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareCodes)
