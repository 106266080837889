const showNotification = notification => {
  return {
    type: 'SHOW',
    notification
  }
}

const resetNotification = () => {
  return {
    type: 'RESET'
  }
}

export { showNotification, resetNotification }
