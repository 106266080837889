import React from 'react'

import appIconGooglePlay from '../../../assets/images/google-play-badge.png'
import appIconAppStore from '../../../assets/images/app-store-badge.png'
import LangPicker from '../LangPicker/LangPicker'
import { FormattedMessage } from 'react-intl'
import {
  StoresLink,
  Description,
  AppLink,
  AppMobileIcon,
  MainContainer,
  BottomSection,
  BottomRow
} from './FooterStyledComponents'

interface Props {
  animate: boolean
  isZeroTouch: boolean
}

const Footer = React.memo(({ animate, isZeroTouch = false, ...rest }: Props) => {
  return (
    <MainContainer delay={100} initialPose={'hidden'} pose={animate && 'visible'} {...rest}>
      <BottomSection>
        <BottomRow>
          <LangPicker />
          <StoresLink>
            <AppLink
              href={isZeroTouch ? 'https://bit.ly/ZeroTouchAndroid' : 'http://bit.ly/HausMartAndroid'}
              target="_blank"
              aria-label="Get HausMart for Android"
            >
              <AppMobileIcon src={appIconGooglePlay} alt="Google Play icon" />
            </AppLink>
            <AppLink
              href={isZeroTouch ? 'https://bit.ly/ZeroTouchiOS' : 'http://bit.ly/HausMartiOS'}
              target="_blank"
              aria-label="Get HausMart for iOS"
            >
              <AppMobileIcon src={appIconAppStore} alt="App store icon" />
            </AppLink>
          </StoresLink>
          <Description>{!isZeroTouch && <FormattedMessage id={'footer.hausmartDescription'} />}</Description>
        </BottomRow>
      </BottomSection>
    </MainContainer>
  )
})

Footer.displayName = 'Footer'

export default Footer
