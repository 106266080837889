import styled from 'styled-components/macro'
import { Container as cont, Row, Col } from 'reactstrap'

const Container = styled(cont).attrs(props => ({
  fluid: true
}))`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Content = styled(Row).attrs(props => ({
  className: 'align-items-stretch'
}))`
  flex: 1;
  display: flex;
  flex-direction: row;
`

const Main = styled(Col)`
  &.col {
    padding: 0;
    background-color: #fff;
  }
`

export { Container, Content, Main }
