import ReactGA from 'react-ga'
/* global fbq, FB */
export default class EventsTracker {
  addToCart (product) {
    if (typeof fbq !== 'undefined') {
      fbq('track', 'AddToCart', product.getFacebookPixelEventObject())
    }
    if (typeof FB !== 'undefined') {
      FB.AppEvents.logEvent(
        FB.AppEvents.EventNames.ADDED_TO_CART,
        product.getPrice(),
        product.getFacebookEventObject()
      )
    }
  }

  checkoutSuccess (cart) {
    const eventTrackingObject = this._getEventTrackingStoreObjectBaseOnCart(cart)
    if (typeof fbq !== 'undefined') {
      fbq('track', 'Purchase', eventTrackingObject)
    }
    if (typeof FB === 'undefined') {
      return
    }

    const appEventsParams = {}
    appEventsParams[FB.AppEvents.ParameterNames.NUM_ITEMS] =
      eventTrackingObject.content_ids.length
    appEventsParams[FB.AppEvents.ParameterNames.CONTENT_TYPE] = 'product_group'
    appEventsParams[FB.AppEvents.ParameterNames.CURRENCY] = 'USD'
    appEventsParams[FB.AppEvents.ParameterNames.CONTENT] =
      eventTrackingObject.contents
    appEventsParams[FB.AppEvents.ParameterNames.CONTENT_ID] =
      eventTrackingObject.content_ids
    FB.AppEvents.logEvent(
      FB.AppEvents.EventNames.PURCHASED,
      eventTrackingObject.value,
      appEventsParams
    )
  }

  initialCheckout (cart) {
    if (typeof FB === 'undefined') {
      return
    }
    const eventTrackingObject = this._getEventTrackingStoreObjectBaseOnCart(cart)
    const appEventsParams = {}
    appEventsParams[FB.AppEvents.ParameterNames.NUM_ITEMS] =
      eventTrackingObject.content_ids.length
    appEventsParams[FB.AppEvents.ParameterNames.CONTENT_TYPE] = 'product_group'
    appEventsParams[FB.AppEvents.ParameterNames.CURRENCY] = 'USD'
    appEventsParams[FB.AppEvents.ParameterNames.CONTENT] =
      eventTrackingObject.contents
    appEventsParams[FB.AppEvents.ParameterNames.CONTENT_ID] =
      eventTrackingObject.content_ids
    FB.AppEvents.logEvent(
      FB.AppEvents.EventNames.INITIATED_CHECKOUT,
      eventTrackingObject.value,
      appEventsParams
    )
  }

  getStartedHomepage () {
    ReactGA.event({
      category: 'link',
      action: 'get_started_from',
      send_to: 'AW-781992770/KLc0COzcyI8BEMKG8fQC'
    })
  }

  _getEventTrackingStoreObjectBaseOnCart (cart) {
    return cart.getProducts().reduce(
      (acc, product) =>
        Object.assign({}, acc, {
          value: acc.value + product.getTotSum(),
          content_ids: [...acc.content_ids, product.getId()],
          contents: [
            ...acc.contents,
            {
              id: product.getId(),
              quantity: product.getCount(),
              item_price: product.price
            }
          ]
        }),
      {
        value: 0,
        content_ids: [],
        contents: [],
        currency: 'USD',
        content_type: 'product_group'
      }
    )
  }
}
