import { createSelector } from 'reselect'

const getCodes = state => state.claimCodes.get('codes')

export const getStatus = state => state.claimCodes.get('status')
export const getStatusDescription = state =>
  state.claimCodes.get('statusDescription')
const findActiveCode = state =>
  state.claimCodes.get('codes').find(item => item.get('is_active') === true)

export const getFreeCodes = createSelector(
  [getCodes],
  codes => codes.filter(item => item.get('is_claimed') === false)
)

export const getActiveCode = createSelector(
  [findActiveCode],
  code => (code ? code.get('short_id') : '')
)

export const getActiveQrCode = createSelector(
  [findActiveCode],
  code => (code ? code.get('qr_code_image') : '')
)

export const isActiveCodeUsed = createSelector(
  [findActiveCode],
  code => code && code.get('is_used')
)

export const isAnyUnusedLeft = createSelector(
  [getCodes],
  codes => codes.some(item => item.get('is_used') === false)
)
