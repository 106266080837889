import has from 'lodash/has'
/* global localStorage */

class LocalStorageService {
  removeStore () {
    localStorage.removeItem('hm-store')
  }

  setCart (cart) {
    localStorage.setItem('hm-cart', JSON.stringify(cart))
  }

  getCart () {
    try {
      let cart = JSON.parse(localStorage.getItem('hm-cart'))
      // this condition is to avoid problems for users with old cart in localstorage
      if (Array.isArray(cart) || !has(cart, 'products')) {
        cart = undefined
      }
      return cart
    } catch (SyntaxError) {
      return []
    }
  }

  clearCart () {
    localStorage.removeItem('hm-cart')
  }

  setGeo (geo) {
    localStorage.setItem('hm-geo', JSON.stringify(geo))
  }

  getGeo () {
    return JSON.parse(localStorage.getItem('hm-geo'))
  }

  setShippingDetails (shippingDetails) {
    shippingDetails &&
      localStorage.setItem('hm-shipping', JSON.stringify(shippingDetails))
  }

  getShippingDetails () {
    try {
      const shippingDetails = JSON.parse(localStorage.getItem('hm-shipping'))
      return shippingDetails
    } catch (SyntaxError) {
      return null
    }
  }

  clearShippingDetails () {
    localStorage.removeItem('hm-shipping')
  }

  setDismissDate () {
    const expirationDate = new Date()
    expirationDate.setMonth(expirationDate.getMonth() + 1)
    localStorage.setItem('hm-dismiss-date', expirationDate.toDateString())
  }

  getDismissDate () {
    const dismissDate = new Date(localStorage.getItem('hm-dismiss-date'))
    return dismissDate
  }

  setUserChatEmail (email) {
    localStorage.setItem('hm-chat-email', JSON.stringify(email))
  }

  getUserChatEmail () {
    try {
      return JSON.parse(localStorage.getItem('hm-chat-email'))
    } catch (SyntaxError) {
      return ''
    }
  }

  clearUserChatEmail () {
    localStorage.removeItem('hm-cart')
  }
}

export default new LocalStorageService()
