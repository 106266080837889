import {
  FETCH_CLAIM_CODES_FAILED,
  FETCH_CLAIM_CODES_INPROGRESS,
  FETCH_CLAIM_CODES_SUCCESS,
  MARK_CODE_AS_UNUSED,
  MARK_CODE_AS_USED,
  SET_ACTIVE_CODE,
  SET_CLAIM_CODES,
  SET_QR_CODE
} from './const'
import claimService from '../api/services/claimService'
import storeService from '../api/services/storeService'
import { getActiveCode, getFreeCodes } from '../redux/selectors/sharedCodes'

export function fetchClaimCodes (storeId, currentActiveCode) {
  return function (dispatch, getState) {
    dispatch(fetchClaimCodesInProgress())
    claimService
      .getClaimCodeByStoreId(storeId)
      .then(resp => {
        dispatch(setClaimCodes(resp))
        dispatch(setActiveCode(currentActiveCode))
        const store = getState()
        dispatch(generateQrCode(getActiveCode(store)))
        dispatch(fetchClaimCodesSuccess())
      })
      .catch(err => {
        console.error('err', err)
        dispatch(fetchClaimCodesFailed(err.response.data.response))
      })
  }
}

export function generateNextActiveCode () {
  return function (dispatch, getState) {
    const prevCode = getActiveCode(getState())
    const freeCodes = getFreeCodes(getState())
    dispatch(setActiveCode(null, freeCodes.size > 1 ? prevCode : null))
    const nextCode = getActiveCode(getState())
    dispatch(generateQrCode(nextCode))
  }
}

export function generateNextActiveCodeAndMarkAsUnused () {
  return function (dispatch, getState) {
    const prevCode = getActiveCode(getState())
    const freeCodes = getFreeCodes(getState())
    dispatch(setActiveCode(null, freeCodes.size > 1 ? prevCode : null))
    const nextCode = getActiveCode(getState())
    dispatch(markCodeAsUnused(nextCode))
    dispatch(generateQrCode(nextCode))
  }
}

export function generateQrCode (code) {
  return function (dispatch) {
    storeService.getStoreQr(code, 'png', false, false).then(resp => {
      const DOMString = window.URL.createObjectURL(new Blob([resp]))
      dispatch(setQrCodeImageForCode(DOMString, code))
    })
  }
}

export function sendCodeViaEmail (storeId, email) {
  return function (dispatch, getState) {
    const activeCode = getActiveCode(getState())
    return claimService
      .sendCodeViaEmail(storeId, email, activeCode)
      .then(() => {
        dispatch(markCodeAsUsed(activeCode))
      })
      .catch(err => {
        throw err.response.data.response
      })
  }
}

export function markCodeAsUsed (code) {
  return {
    type: MARK_CODE_AS_USED,
    code
  }
}

export function markCodeAsUnused (code) {
  return {
    type: MARK_CODE_AS_UNUSED,
    code
  }
}

export function setQrCodeImageForCode (image, forCode) {
  return {
    type: SET_QR_CODE,
    image,
    forCode
  }
}

export function fetchClaimCodesSuccess () {
  return {
    type: FETCH_CLAIM_CODES_SUCCESS
  }
}

export function fetchClaimCodesFailed (reason) {
  return {
    type: FETCH_CLAIM_CODES_FAILED,
    reason
  }
}

export function fetchClaimCodesInProgress () {
  return {
    type: FETCH_CLAIM_CODES_INPROGRESS
  }
}

export function setClaimCodes (codes) {
  return {
    type: SET_CLAIM_CODES,
    codes
  }
}

export function setActiveCode (codeProposal = null, codeExcluded = null) {
  return {
    type: SET_ACTIVE_CODE,
    codeProposal,
    codeExcluded
  }
}
