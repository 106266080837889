import styled from 'styled-components/macro'
import { prop } from 'styled-tools'

const CircleIcon = styled.div`
  border: 1px solid ${prop('color', 'currentColor')};
  border-radius: 100%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${prop('width', '1rem')};
  height: ${prop('height', '1rem')};
`
export { CircleIcon }
