import HausmartService from './HausmartService'

class PreparedCartService extends HausmartService {
  sendCart (_id, items, email, name) {
    return this.api.post(``, {
      store: {
        _id
      },
      items: items.map(item =>
        Object.assign({}, item, { quantity: item.count })
      ),
      email,
      sender_name: name
    })
  }

  getCart (code) {
    return this.api.get(`/${code}`)
  }
}

export default new PreparedCartService('/v2/prepared_cart')
