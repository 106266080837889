export default [
  {
    code: 'AL',
    postal_code: '35816'
  },
  {
    code: 'AK',
    postal_code: '99524'
  },
  {
    code: 'AZ',
    postal_code: '85055'
  },
  {
    code: 'AR',
    postal_code: '72217'
  },
  {
    code: 'CA',
    postal_code: '94209'
  },
  {
    code: 'CO',
    postal_code: '80239'
  },
  {
    code: 'CT',
    postal_code: '06112'
  },
  {
    code: 'DE',
    postal_code: '19905'
  },
  {
    code: 'DC',
    postal_code: '20020'
  },
  {
    code: 'FL',
    postal_code: '32509'
  },
  {
    code: 'GA',
    postal_code: '30381'
  },
  {
    code: 'HI',
    postal_code: '96830'
  },
  {
    code: 'ID',
    postal_code: '83254'
  },
  {
    code: 'IL',
    postal_code: '60641'
  },
  {
    code: 'IN',
    postal_code: '46209'
  },
  {
    code: 'IA',
    postal_code: '52809'
  },
  {
    code: 'KS',
    postal_code: '67221'
  },
  {
    code: 'KY',
    postal_code: '41702'
  },
  {
    code: 'LA',
    postal_code: '70119'
  },
  {
    code: 'ME',
    postal_code: '04034'
  },
  {
    code: 'MD',
    postal_code: '21237'
  },
  {
    code: 'MA',
    postal_code: '02137'
  },
  {
    code: 'MI',
    postal_code: '49036'
  },
  {
    code: 'MN',
    postal_code: '55808'
  },
  {
    code: 'MS',
    postal_code: '39535'
  },
  {
    code: 'MO',
    postal_code: '63141'
  },
  {
    code: 'MT',
    postal_code: '59044'
  },
  {
    code: 'NE',
    postal_code: '68902'
  },
  {
    code: 'NV',
    postal_code: '89513'
  },
  {
    code: 'NH',
    postal_code: '07039'
  },
  {
    code: 'NJ',
    postal_code: '87506'
  },
  {
    code: 'NM',
    postal_code: '87506'
  },
  {
    code: 'NY',
    postal_code: '10048'
  },
  {
    code: 'NC',
    postal_code: '27565'
  },
  {
    code: 'ND',
    postal_code: '58282'
  },
  {
    code: 'OH',
    postal_code: '44179'
  },
  {
    code: 'OK',
    postal_code: '74110'
  },
  {
    code: 'OR',
    postal_code: '97225'
  },
  {
    code: 'PA',
    postal_code: '15244'
  },
  {
    code: 'PR',
    postal_code: '00603'
  },
  {
    code: 'RI',
    postal_code: '02841'
  },
  {
    code: 'SC',
    postal_code: '29020'
  },
  {
    code: 'SD',
    postal_code: '57402'
  },
  {
    code: 'TN',
    postal_code: '37222'
  },
  {
    code: 'TX',
    postal_code: '78705'
  },
  {
    code: 'UT',
    postal_code: '84323'
  },
  {
    code: 'VT',
    postal_code: '05751'
  },
  {
    code: 'VA',
    postal_code: '24517'
  },
  {
    code: 'WA',
    postal_code: '98009'
  },
  {
    code: 'WV',
    postal_code: '25813'
  },
  {
    code: 'WI',
    postal_code: '53228'
  },
  {
    code: 'WY',
    postal_code: '82941'
  }
]
