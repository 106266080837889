import React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { injectIntl } from 'react-intl'

const TopActionWidget = loadable(() => import('../TopActionWidget/TopActionWidget'))

const ActiveStoreWidget = React.memo(
  ({ onClickBackToStore, onClickClose, isCookiesAccepted, name, intl }) => (
    <TopActionWidget
      spacingTop={!isCookiesAccepted}
      title={`${intl.formatMessage({ id: 'general.backTo' })} ${name}`}
      onClickAction={onClickBackToStore}
      onClickClose={onClickClose}
    />
  )
)

ActiveStoreWidget.displayName = 'ActiveStoreWidget'

ActiveStoreWidget.propTypes = {
  onClickBackToStore: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
  isCookiesAccepted: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired
}

export default injectIntl(ActiveStoreWidget)
