import CoreApi from '../CoreApi'

class HausmartService extends CoreApi {
  constructor (endpoint) {
    super()

    // use global enpoint for service if needed, i.e '/utils'
    if (endpoint) {
      this.endpoint = endpoint
      this.setEndpointUrl(this.endpoint)
    }
  }

  setEndpointUrl (url) {
    this.api.defaults.baseURL = this.baseUrl + url
  }
}

export default HausmartService
