import { fromJS, List } from 'immutable'
import {
  FETCH_CLAIM_CODES_FAILED,
  FETCH_CLAIM_CODES_INPROGRESS,
  FETCH_CLAIM_CODES_SUCCESS,
  MARK_CODE_AS_UNUSED,
  MARK_CODE_AS_USED,
  SET_ACTIVE_CODE,
  SET_CLAIM_CODES,
  SET_QR_CODE
} from '../../actions/const'

export const initialClaimCodes = fromJS({
  codes: List(),
  status: null, // SUCCESS | IN_PROGRESS | ERROR
  statusDescription: null
})

export default (state = initialClaimCodes, action) => {
  switch (action.type) {
    case SET_CLAIM_CODES:
      return state
        .set('codes', fromJS(action.codes))
        .update('codes', codeList =>
          codeList.map(code =>
            code.set('is_used', false).set('is_active', false)
          )
        )

    case FETCH_CLAIM_CODES_SUCCESS:
      return state.set('status', 'SUCCESS')

    case FETCH_CLAIM_CODES_INPROGRESS:
      return state.set('status', 'IN_PROGRESS')

    case FETCH_CLAIM_CODES_FAILED:
      return state
        .set('status', 'ERROR')
        .set('statusDescription', action.reason)

    case SET_ACTIVE_CODE: {
      try {
        let codes = state
          .get('codes')
          .filter(code => code.get('is_claimed') === false)
        const newCode =
          // get one from url param
          (action.codeProposal &&
            codes.find(item => item.get('short_id') === action.codeProposal)) ||
          // otherwise get one first not used and exlcuded
          codes.find(
            item =>
              item.get('is_used') === false &&
              item.get('short_id') !== action.codeExcluded
          ) ||
          // otherwise get first after active one
          codes.reduce((acc, item, index) =>
            item.get('is_active') === true
              ? codes.get(index + 1) || codes.get(1)
              : acc
          )
        const index = codes.findIndex(
          code => code.get('short_id') === newCode.get('short_id')
        )
        codes = codes
          .map(code => code.set('is_active', false))
          .setIn([index, 'is_active'], true)
        return state.set('codes', codes)
      } catch (e) {
        console.error(e)
        return state
      }
    }

    case SET_QR_CODE: {
      try {
        const indexOfListingToUpdate = state.get('codes').findIndex(listing => {
          return listing.get('short_id') === action.forCode
        })
        return state.setIn(
          ['codes', indexOfListingToUpdate, 'qr_code_image'],
          action.image
        )
      } catch (e) {
        console.error(e)
        return state
      }
    }

    case MARK_CODE_AS_USED: {
      try {
        const index = state
          .get('codes')
          .findIndex(code => code.get('short_id') === action.code)
        return state.setIn(['codes', index, 'is_used'], true)
      } catch (e) {
        console.error(e)
        return state
      }
    }
    case MARK_CODE_AS_UNUSED: {
      try {
        const index = state
          .get('codes')
          .findIndex(code => code.get('short_id') === action.code)
        return state.setIn(['codes', index, 'is_used'], false)
      } catch (e) {
        console.error(e)
        return state
      }
    }

    default:
      return state
  }
}
