const accept = notification => {
  return {
    type: 'ACCEPT_COOKIE',
    notification
  }
}

const disagree = () => {
  return {
    type: 'DISAGREE_COOKIE'
  }
}

const makeUnset = () => {
  return {
    type: 'UNSET_COOKIE'
  }
}

const updateState = (accepted, version) => {
  return {
    type: 'UPDATE_COOKIE',
    accepted,
    version
  }
}

export { accept, disagree, makeUnset, updateState }
