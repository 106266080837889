import styled from 'styled-components/macro'
import DefaultFooter from '../../components/layout/Footer/Footer'
import { theme } from 'styled-tools'
import { darken, lighten } from 'polished'
import { Button as ReactstrapButton } from 'reactstrap'
import { media } from '../../ui/rwd'
import posed from 'react-pose'

const Footer = styled(DefaultFooter)``

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`

const ClaimInfoBox = styled.div`
  border-bottom: ${props =>
    `1px solid ${darken(0.075, props.theme.lightenGray)}`};
  border-top: ${props => `1px solid ${darken(0.075, props.theme.lightenGray)}`};
  display: flex;
  width: 100%;
  padding: 1rem;
  align-items: center;
  ${media.sm`
    padding:2rem;
  `}
`

const Logo = styled.img`
  height: 4rem;
`

const QrCode = styled.img`
  width: auto;
  height: 5rem;
`
const ClaimDescription = styled.div`
  margin-left: 1rem;
  text-align: left;
`

const ClaimCode = styled.h4`
  margin-bottom: 0;
`

const ClaimLink = styled.a`
  font-size: 1rem;
  color: ${theme('black')};
  text-decoration: underline;
  display: block;
`

const Button = styled(ReactstrapButton)`
  margin-top: 5px;
  width: 100%;
  min-height: 3rem;
  ${media.sm`
    min-height: auto;
    width: auto;
    margin-top: 0px;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  `}
`

const GenerateButton = styled(Button)`
  margin-top: 5px;
  ${media.sm`
    margin-top: 5px;
  `}
`

const ShareContener = styled.div`
  width: 100%;
  padding: 1rem;
  text-align: left;
  ${media.sm`
    padding:2rem;
  `}
`

const FormGroup = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Label = styled.label`
  font-weight: bold;
`

const Input = styled.input`
  flex: 1;
  z-index: 2;
  padding: 0.4rem;
  &:focus {
    outline: ${props => `2px solid ${lighten(0.2, props.theme.secondary)}`};
  }
  ${media.sm`
     margin-right: 1rem;
  `}
`
const AnimateElementTop = posed.div({
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 150,
      damping: 15
    }
  },

  exit: {
    opacity: 0,
    y: -50,
    transition: {
      ease: 'easeOut',
      duration: 500
    }
  }
})

const SuccessMessage = styled(AnimateElementTop)`
  color: ${theme('success')};
  font-size: 1rem;
`

const InfoMessage = styled(AnimateElementTop)`
  font-size: 1rem;
  color: #808080;
`

const ErrorMessage = styled(AnimateElementTop)`
  font-size: 1rem;
  color: ${theme('danger')};
`

const RefreshClaimLink = styled.a`
  text-decoration: underline !important;
  cursor: pointer;
  font-weight: bold;
`

const ChannelContainer = styled.div`
  margin-top: 2rem;
`

const SocialButtonsContainer = styled.div`
  display: flex;
  margin-top: 0.2rem;
  flex-direction: row;
  justify-content: flex-start;
  div[role='button'] {
    margin-right: 5px;
    cursor: pointer;
  }
`

const ButtonUppercase = styled(Button)`
  text-transform: uppercase;
`

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
`

export {
  Footer,
  Container,
  Header,
  Logo,
  ClaimInfoBox,
  ClaimDescription,
  QrCode,
  ClaimLink,
  ClaimCode,
  GenerateButton,
  ShareContener,
  FormGroup,
  Label,
  Input,
  Button,
  SuccessMessage,
  RefreshClaimLink,
  ChannelContainer,
  SocialButtonsContainer,
  ButtonUppercase,
  CenterContainer,
  InfoMessage,
  ErrorMessage
}
