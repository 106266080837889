import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { theme } from 'styled-tools'

const NavigationTileWrapper = styled(Link)`
  margin: 0.2em 0.5em;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${theme('black')};
  width: 4rem;
  transition: color 0.3s;
  & svg {
    font-size: 1.5em;
  }
`

const NavigationTileLabel = styled.span`
  font-size: 0.55em;
  text-align: center;
`

export { NavigationTileWrapper, NavigationTileLabel }
