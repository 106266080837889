const initialState = {
  latest: '',
  terms: undefined
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LATEST_VERSION':
      return { ...state, latest: action.latestVersion }
    case 'SET_TERMS':
      return { ...state, terms: action.terms }
    default:
      return state
  }
}
