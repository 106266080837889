import Fuse from 'fuse.js'
import take from 'lodash/take'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Input } from 'reactstrap'
import en from '../../../faqData/en.json'
import es from '../../../faqData/es.json'
import is from '../../../faqData/is.json'
import { InputWrapper, Overlay, Result, ResultDescription, ResultsList, ResultTitle, SearchBarWrapper } from './SearchBarStyledComponents'

const findResults = (fuse, setResults, setFocusKey, event) => {
  if (event.target.value && event.target.value.length > 1) {
    const results = fuse
      .search(event.target.value)
      .filter(result => result.score < 0.3)
    setResults(results)
  } else {
    setResults([])
  }
  setFocusKey(-1)
}

const onKeyDown = (
  focusKey,
  setFocusKey,
  results,
  history,
  setFocus,
  event
) => {
  switch (event.keyCode) {
    case 40: {
      if (focusKey < results.length && focusKey < 4) {
        setFocusKey(focusKey + 1)
      }
      break
    }
    case 38: {
      if (focusKey > 0) {
        setFocusKey(focusKey - 1)
      }
      break
    }
    case 13: {
      setFocus(false)
      history.push('/faq', { foundResult: results[focusKey] })
      break
    }
    default:
      break
  }
}

const chooseLangData = lang => {
  switch (lang) {
    case 'en':
      return en
    case 'es':
      return es
    case 'is':
      return is
    default:
      return []
  }
}

const onHoverResult = (setFocusKey, event) => {
  const id = event.target.id
  setFocusKey(Number(id.replace('result_', '')))
}

const fuseOptions = {
  keys: [{ name: 'question', weight: 0.7 }, { name: 'answer', weight: 0.3 }],
  includeScore: true,
  minMatchCharLength: 2,
  shouldSort: true
}

function SearchBar () {
  const intl = useIntl()
  const history = useHistory()
  const lang = useSelector(state => state.language.langCode)
  const [results, setResults] = useState([])
  const [fuse, setFuse] = useState(null)
  const [focusKey, setFocusKey] = useState(-1)
  const [focus, setFocus] = useState(false)

  useEffect(() => {
    setFuse(new Fuse(chooseLangData(lang), fuseOptions))
  }, [lang])

  return (
    <SearchBarWrapper>
      <InputWrapper>
        <Input
          name='search_bar'
          onFocus={() => {
            setFocus(true)
          }}
          id='search_bar-input'
          placeholder={intl.formatMessage({ id: 'searchBar.search' })}
          onKeyDown={onKeyDown.bind(
            this,
            focusKey,
            setFocusKey,
            results,
            history,
            setFocus
          )}
          onChange={findResults.bind(this, fuse, setResults, setFocusKey)}
        />
        {focus && (
          <ResultsList>
            {take(results, 5).map((result, index) => (
              <Result
                className={`${focusKey === index ? 'hovered' : ''}`}
                onMouseEnter={onHoverResult.bind(this, setFocusKey)}
                id={`result_${index}`}
                key={`result_${index}`}
                onClick={() => {
                  setFocus(false)
                  history.push('/faq', { foundResult: result })
                }}
              >
                <ResultTitle>{result.item.question}</ResultTitle>
                <ResultDescription>
                  {result.item.answer.replace(/<\/?[^>]+(>|$)/g, '')}
                </ResultDescription>
              </Result>
            ))}
          </ResultsList>
        )}
      </InputWrapper>
      {focus && results.length > 0 && (
        <Overlay
          onClick={() => {
            setFocus(false)
          }}
        />
      )}
    </SearchBarWrapper>
  )
}

export default SearchBar
