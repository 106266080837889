import React from 'react'
import { NavLink } from 'react-router-dom'
import { Breadcrumbs } from 'react-breadcrumbs-dynamic'
import { DynamicBreadcrumbWrapper } from './DynamicBreadcrumbStyledComponents'

function DynamicBreadcrumb () {
  return (
    <DynamicBreadcrumbWrapper>
      <Breadcrumbs
        separator={<b> &gt; </b>}
        container={'span'}
        containerProps={{
          style: {
            color: '#222',
            fontSize: '18px'
          }
        }}
        item={NavLink}
        finalItem={'b'}
        finalProps={{
          style: {
            color: '#222',
            fontSize: '18px',
            textDecoration: 'underline'
          },
          active: 'true'
        }}
      />
    </DynamicBreadcrumbWrapper>
  )
}

DynamicBreadcrumb.displayName = 'DynamicBreadcrumb'

export default DynamicBreadcrumb
