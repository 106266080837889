import styled from 'styled-components/macro'
import { ifProp, theme } from 'styled-tools'
import { media } from '../../../ui/rwd'

const Wrapper = styled.div`
  transition: 0.4s ease-in-out;
  height: auto;
  max-height: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: ${ifProp('contentProvided', '40vh', '0')};
`

const ContentItem = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f9de6b;
  background: ${theme('yellow')};
  padding: 3rem 1rem;
  width: 100vw;
  max-width: calc(100% + 60px);
  flex-grow: 1;
  ${media.sm.down`
    & * {
      font-size: 1.1em !important;
    }`}
`

const BanerContainer = styled.div`
  padding: 0 !important;
  ${media.sm`
      ${ifProp(
    { fluid: true },
    'padding: 0 !important',
    'padding: 0px 15px !important'
  )};
  `}
`

export { Wrapper, ContentItem, BanerContainer }
