import React from 'react'
import {
  NavigationTileWrapper,
  NavigationTileLabel
} from './NavigationTileStyledComponents'
import PropTypes from 'prop-types'

function NavigationTile ({ Icon, text, link }) {
  return (
    <NavigationTileWrapper to={link}>
      <Icon />
      <NavigationTileLabel>{text}</NavigationTileLabel>
    </NavigationTileWrapper>
  )
}

NavigationTile.displayName = 'NavigationTile'

NavigationTile.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
}

export default NavigationTile
