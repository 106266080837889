import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

import { Redirect, Switch, withRouter, Route } from 'react-router-dom'
import NotFound from './pages/NotFound/NotFound'
import ShareCodes from './pages/ShareCodes/ShareCodes'
import RawLayout from './components/layout/RawLayout/RawLayout'
import DefaultLayout from './components/layout/DefaultLayout/DefaultLayout'
import RouteWithLayout from './components/common/RouteWithLayout'
import ActiveStoreWidget from './components/shared/ActiveStoreWidget/ActiveStoreWidget'
import BrowserNotSupported from './components/common/BrowserNotSupported'
import Metatags from './components/shared/Metatags/Metatags'
import MailLayout from './components/layout/MailLayout/MailLayout'
// other
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import NotificationSystem from 'react-notification-system'
import * as actionsNotification from './actions/notification'
import * as actionsActiveStore from './actions/activeStore'
import * as actionsCart from './actions/cart'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactGA from 'react-ga'
import { injectIntl } from 'react-intl'
import { isIE } from 'react-device-detect'
import handleIntercomVisibility from './helpers/intercom'
/* import Owner from './pages/Owner/Owner' */
import RedirectProxy from './pages/RedirectProxy/RedirectProxy'
// pages
const Homepage = loadable(() => import('./pages/Homepage/Homepage'))
const Faq = loadable(() => import('./pages/FAQ/Faq'))
const Contact = loadable(() => import('./pages/Contact/Contact'))
const Terms = loadable(() => import('./pages/Terms/Terms'))
// const Theme = loadable(() => import('./pages/Theme/Theme'))
const Store = loadable(() => import('./pages/Store/Store'))
const LinkCheck = loadable(() => import('./pages/LinkCheck/LinkCheck'))
const GetQr = loadable(() => import('./pages/GetQr/GetQr'))
const MyPop = loadable(() => import('./pages/MyPop/MyPop'))
const Product = loadable(() => import('./pages/Product/Product'))
const StoreSelector = loadable(() => import('./pages/StoreSelector/StoreSelector'))
const About = loadable(() => import('./pages/About/About'))
const Mission = loadable(() => import('./pages/Mission/OurMission'))
const Testimonials = loadable(() => import('./pages/Testimonials/Testimonials'))
const GeolocationModal = loadable(() => import('./components/shared/GeolocationModal/GeolocationModal'))

class App extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    notification: PropTypes.object,
    intl: PropTypes.object.isRequired,
    actionsNotification: PropTypes.object.isRequired,
    actionsActiveStore: PropTypes.object.isRequired,
    actionsCart: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    cookiesState: PropTypes.object.isRequired
  }

  notificationSystem = React.createRef()

  state = {
    geolocationModalShow: false,
    showFooterInStore: false
  }

  componentDidMount() {
    const app = document.getElementById('react-app')
    app && app.removeAttribute('style')
    ReactGA.initialize('UA-127155717-1')
    this.props.history.listen(location => {
      ReactGA.pageview(location.pathname)
    })
    ReactGA.pageview(this.props.history.location.pathname)
    handleIntercomVisibility(this.props.location)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
    if (this.props.notification !== null) {
      this.showNotification(this.props.notification)
    }
  }

  onRouteChanged = () => {
    this.setState({ showFooterInStore: false })
    handleIntercomVisibility(this.props.location)
  }

  showNotification = notification => {
    this.notificationSystem.current &&
      this.notificationSystem.current.addNotification({
        title:
          notification.title ||
          this.props.intl.formatMessage({
            id: 'general.defaultNotificationTitle'
          }),
        message:
          notification.message ||
          this.props.intl.formatMessage({
            id: 'general.defaultNotificationMessage'
          }),
        level: notification.type || 'info',
        position: notification.position || 'tr',
        dismissible: notification.dismissible || 'both',
        autoDismiss: notification.autoDismiss !== undefined ? notification.autoDismiss : 5,
        action: notification.action || null,
        children: notification.children || null,
        onAdd: notification.onAdd || null,
        onRemove: notification.onRemove || null,
        uid: notification.uid || null
      })
    // this.notificationSystem.current.clearNotifications();
    this.props.actionsNotification.resetNotification()
  }

  onBackToStore = () => {
    this.props.history.push(`/store/${this.props.cart.getStoreId()}`)
  }

  onCloseActiveStoreWidget = () => {
    this.props.actionsActiveStore.clearActiveStore()
    this.props.actionsCart.clearCart()
  }

  toggleGeolocationModal = () => {
    this.setState({
      geolocationModalShow: !this.state.geolocationModalShow
    })
  }

  isInBlackListedPage() {
    const locationMain = this.props.location.pathname.split('/')[1]
    const blacklist = ['paymentsuccess', 'paymenterror', 'link', 'policy', 'qr', 'store']
    const isInBlackListedPage = blacklist.includes(locationMain)

    return isInBlackListedPage
  }

  displayActiveStoreWidget() {
    return this.props.cart.products.length > 0 && !this.isInBlackListedPage()
  }

  showFooter = () => {
    this.setState({ showFooterInStore: true })
  }

  render() {
    const { intl } = this.props
    if (isIE) return <BrowserNotSupported />
    return (
      <>
        <BreadcrumbsItem key="breadcrumbKey" to={'/'}>
          Home page
        </BreadcrumbsItem>
        <Metatags key="metatags" />
        <Suspense>
          <Switch key="switchKey">
            <RouteWithLayout
              layout={DefaultLayout}
              component={Homepage}
              tTitle={intl.formatMessage({ id: 'tTitle.home' })}
              path="/"
              showNotification={this.showNotification}
              toggleGeolocationModal={this.toggleGeolocationModal}
              exact
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={Homepage}
              tTitle={intl.formatMessage({ id: 'tTitle.home' })}
              path="/home/message-send"
              showNotification={this.showNotification}
              exact
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={StoreSelector}
              tTitle={intl.formatMessage({ id: 'tTitle.store' })}
              path="/store"
              exact
              showNotification={this.showNotification}
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={Store}
              animateFooter={this.state.showFooterInStore}
              onStoreLoaded={this.showFooter}
              tTitle={intl.formatMessage({ id: 'tTitle.store' })}
              path="/store/:storeId"
              toggleGeolocationModal={this.toggleGeolocationModal}
              exact
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={Product}
              tTitle={intl.formatMessage({ id: 'tTitle.store' })}
              path="/store/:storeId/product/:productId"
              exact
              showNotification={this.showNotification}
              toggleGeolocationModal={this.toggleGeolocationModal}
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={Faq}
              tTitle={intl.formatMessage({ id: 'tTitle.faq' })}
              path="/faq"
              breadcrumb={'Faq'}
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={Contact}
              tTitle={intl.formatMessage({ id: 'tTitle.contact' })}
              path="/contact/:status?"
              breadcrumb={'Contact'}
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={Contact}
              tTitle={intl.formatMessage({ id: 'tTitle.contact' })}
              path="/enterprise"
              breadcrumb={'Contact'}
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={Terms}
              tTitle={intl.formatMessage({ id: 'tTitle.terms' })}
              path="/terms/:activeTab?/:tabName?"
              breadcrumb={'Terms'}
              showNotification={this.showNotification}
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={About}
              tTitle={intl.formatMessage({ id: 'tTitle.about' })}
              path="/about"
              breadcrumb={'About'}
              showNotification={this.showNotification}
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={Mission}
              tTitle={intl.formatMessage({ id: 'tTitle.mission' })}
              path="/mission"
              breadcrumb={'Mission'}
              showNotification={this.showNotification}
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={Testimonials}
              tTitle={intl.formatMessage({ id: 'tTitle.testimonials' })}
              path="/testimonials"
              breadcrumb={'Testimonials'}
              showNotification={this.showNotification}
            />
            {/* <RouteWithLayout
            layout={RawLayout}
            component={Theme}
            tTitle=' HausMart - Theme'
            path='/theme'
            breadcrumb={'Theme'}
            title={<h1 className='text-white text-center'>theme layout</h1>}
          /> */}
            <RouteWithLayout
              layout={RawLayout}
              component={Terms}
              tTitle={intl.formatMessage({ id: 'tTitle.terms' })}
              path="/policy/:activeTab?/:tabName?"
              breadcrumb={'Terms'}
            />
            <Route component={RedirectProxy} path="/cart/:code" />
            <RouteWithLayout
              layout={MailLayout}
              component={ShareCodes}
              tTitle={intl.formatMessage({ id: 'tTitle.invite' })}
              path="/invite"
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={LinkCheck}
              tTitle={intl.formatMessage({ id: 'tTitle.link' })}
              path="/link"
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={GetQr}
              tTitle={intl.formatMessage({ id: 'tTitle.qr' })}
              path="/qr"
              breadcrumb={'QR'}
              showNotification={this.showNotification}
            />
            <RouteWithLayout
              layout={DefaultLayout}
              component={MyPop}
              tTitle={intl.formatMessage({ id: 'tTitle.myPop' })}
              path="/mypop"
              breadcrumb={'MyPop'}
              showNotification={this.showNotification}
            />
            {/* <RouteWithLayout layout={DefaultLayout} component={EmbededWidgetGenerator} tTitle={intl.formatMessage({ id: 'tTitle.embedable' })} path='/embed' breadcrumb={'Embedable Widget'} showNotification={this.showNotification} /> */}
            {/* <RouteWithLayout layout={RawLayout} component={Owner} tTitle={intl.formatMessage({ id: 'tTitle.qr' })} path='/owner/:storeId' breadcrumb={'Owner'} showNotification={this.showNotification} /> */}
            <Redirect exact from="/home" to="/" />
            <RouteWithLayout
              layout={DefaultLayout}
              tTitle={intl.formatMessage({ id: 'tTitle.notFound' })}
              component={NotFound}
            />
          </Switch>
        </Suspense>
        <NotificationSystem key="notiCont" ref={this.notificationSystem} />
        <div className="store-widget-wrapper" key="storeWidgetContainer">
          {this.displayActiveStoreWidget() && (
            <ActiveStoreWidget
              isCookiesAccepted={this.props.cookiesState.accepted}
              name={this.props.cart.getCartStoreName()}
              onClickClose={this.onCloseActiveStoreWidget}
              onClickBackToStore={this.onBackToStore}
            />
          )}
        </div>
        {this.state.geolocationModalShow && (
          <GeolocationModal
            key="geoModal"
            isOpen={this.state.geolocationModalShow}
            toggle={this.toggleGeolocationModal}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    notification: state.notification,
    activeStore: state.activeStore,
    cookiesState: state.cookies,
    cart: state.cart
  }
}

const mapDispatchToProps = dispatch => ({
  actionsNotification: bindActionCreators(actionsNotification, dispatch),
  actionsCart: bindActionCreators(actionsCart, dispatch),
  actionsActiveStore: bindActionCreators(actionsActiveStore, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(App)))
