import React from 'react'
import styled from 'styled-components/macro'
import { media } from '../../../ui/rwd'
import { Col, Row, Container } from 'reactstrap'
import { MdMenu, MdClear } from 'react-icons/md'
import CircularButton from '../../shared/CircularButton/CircularButton'
import { theme } from 'styled-tools'

const LogoCol = styled(Col)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.lg`
     justify-content: flex-start;
  `}
`

const TopBarRow = styled(Row)`
  padding: 2rem 0;
  align-items: center;
  flex: 1;
`

const EnterStoreBoxCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.lg`
     justify-content: flex-end;
  `}
`
const LeftCol = styled(Col)`
  ${media.md`
    display: none;
  `}
`

const RightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding-left: 0 !important;
  ${media.md`
    display: none;
  `}
`

const Wrapper = styled(Container)`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
`

const ClearIcon = styled(MdClear)`
  @keyframes rotate {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  animation: rotate 0.4s;
`

const MenuIcon = styled(MdMenu)`
  @keyframes scale {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  animation: scale 0.4s;
`

const MenuIndicator = styled(({ toggled, ...props }) => (
  <CircularButton {...props}>{toggled ? <ClearIcon /> : <MenuIcon />}</CircularButton>
))`
  position: absolute;
  top: 37px;
  right: 0.5rem;
  z-index: 10;
  font-size: 1.3em;
`

const ContactWrapper = styled.div`
  position: absolute;
  top: 37px;
  right: 0.5rem;
  z-index: 10;
  font-size: 1.3em;
`

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 10;
  padding: 1.5em 0;
  background: ${theme('white')};
  animation: 0.5s fadeIn;
  ${media.md.down`
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  `}
  & input {
    font-size: 2rem !important;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export { LeftCol, RightCol, EnterStoreBoxCol, TopBarRow, LogoCol, MenuWrapper, Wrapper, MenuIndicator, ContactWrapper }
