import React from 'react'
import styled from 'styled-components/macro'
import { media } from 'ui/rwd'
import Uplands from '../../assets/images/uplands-bg.png'
import Circles from '../../assets/images/circles-bg.png'
import FlamingoSVG from '../../assets/svg/flamingo.svg'
import FlamingoDesktopSVG from '../../assets/svg/flamingoDesktop.svg'
import CupsSVG from '../../assets/svg/cup.svg'
import CactusSVG from '../../assets/svg/cactus.svg'
import BallSVG from '../../assets/svg/basket-ball.svg'
import { isMobile } from 'react-device-detect'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: space-between;
  background-image: url(${Circles});
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 2rem;
  width: 100vw;
  margin-left: -15px;
  ${media.sm`
    background-image: url(${Uplands});
    padding: 1rem;
    width: calc(100vw - 17px);
  `}
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
`

const LogoWrapper = styled.div`
  z-index: 1;
  margin-top: 0.5rem;
  ${media.sm`
    margin-top: 2rem;
  `}
`

const Asset = styled.div`
  position: absolute;
`

const Flamingo = () => (
  <FlamingoWrapper>
    {isMobile ? (
      <img src={FlamingoSVG} alt='' />
    ) : (
      <img src={FlamingoDesktopSVG} alt='' />
    )}
  </FlamingoWrapper>
)

const FlamingoWrapper = styled(Asset)`
  top: 6vh;
  left: 0;
  ${media.sm`
    top: 0;
    left: 13vw;
  `}
`

const CupsWrapper = styled(Asset)`
  top: 16vh;
  left: 60vw;
  ${media.sm`
    top: 20vh;
  `}
`

const Cups = () => (
  <CupsWrapper>
    <img src={CupsSVG} alt='' />
  </CupsWrapper>
)

const CactusWrapper = styled(Asset)`
  bottom: 0;
  left: 0;
  ${media.sm`
    left: 20vw;
  `}
`

const Cactus = () => (
  <CactusWrapper>
    <img src={CactusSVG} alt='' />
  </CactusWrapper>
)

const BallWrapper = styled(Asset)`
  bottom: 30vh;
  right: 10vw;
  ${media.sm`
    bottom: 10vh;
    right: 40vw;
  `}
`

const Ball = () => (
  <BallWrapper>
    <img src={BallSVG} alt='' />
  </BallWrapper>
)

const Heading = styled.h1`
  font-size: 4em;
`

export {
  Wrapper,
  ContentWrapper,
  LogoWrapper,
  Flamingo,
  Cups,
  Cactus,
  Ball,
  Heading
}
