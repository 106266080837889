import Product from '../../api/models/Product'
import { CLEAR_ACTIVE_PRODUCT, SET_ACTIVE_PRODUCT } from '../../actions/const'

const initialState = new Product()

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PRODUCT:
      return new Product(action.product)

    case CLEAR_ACTIVE_PRODUCT:
      return new Product()

    default:
      return state
  }
}
