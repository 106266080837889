// PRODUCT ACTIONS
export const SET_ACTIVE_PRODUCT = 'SET_ACTIVE_PRODUCT'
export const CLEAR_ACTIVE_PRODUCT = 'CLEAR_ACTIVE_PRODUCT'
export const FETCHING_PRODUCT = 'FETCHING_PRODUCT'
export const FETCHING_PRODUCT_FINISHED = 'FETCHING_PRODUCT_FINISHED'

// CLAIM CODES

export const FETCH_CLAIM_CODES = 'FETCH_CLAIM_CODES'
export const SET_CLAIM_CODES = 'SET_CLAIM_CODES'
export const FETCH_CLAIM_CODES_INPROGRESS = 'FETCH_CLAIM_CODES_INPROGRESS'
export const FETCH_CLAIM_CODES_SUCCESS = 'FETCH_CLAIM_CODES_SUCCESS'
export const FETCH_CLAIM_CODES_FAILED = 'FETCH_CLAIM_CODES_FAILED'
export const SET_ACTIVE_CODE = 'SET_ACTIVE_CODE'
export const SET_QR_CODE = 'SET_QR_CODE'
export const MARK_CODE_AS_USED = 'MARK_CODE_AS_USED'
export const MARK_CODE_AS_UNUSED = 'MARK_CODE_AS_UNUSED'
