import styled from 'styled-components/macro'
import { theme } from 'styled-tools'

const DynamicBreadcrumbWrapper = styled.div`
  a {
    color: ${theme('dark')};
  }

  a:hover {
    color: ${theme('dark')};
    opacity: 0.5;
  }
`

export { DynamicBreadcrumbWrapper }
